import React, { Component } from "react"
import { Tabs, Tab } from "react-bootstrap"
import { Button } from "primereact/button"
import { InputMask } from "primereact/inputmask"
import { InputText } from "primereact/inputtext"
import { Messages } from "primereact/messages"
import { Upload } from "../Forms/Upload"
import { DataGrid } from "../DataGrid"
import { locale } from "../../constants/language.config"

import { AjaxApiFetch } from "../../Utils/ajaxApiFetch"

class BlackListTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      issn: "",
      refresh: false,
    }

    this.saveBlacklistForm = this.saveBlacklistForm.bind(this)
    this.printMessage = this.printMessage.bind(this)
  }

  printMessage(severity, status, detail) {
    this.messages.show({
      severity,
      summary: status,
      closable: false,
      detail,
    })
  }

  saveBlacklistForm(ev) {
    const { issn, title } = this.state
    AjaxApiFetch({
      path: "upload-blacklist-single",
      method: "POST",
      data: { issn, title },
    })
      .then((data) => {
        this.printMessage("success", `${locale["Blacklist data inserted"]}`)

        this.setState({
          refresh: true,
        })
      })
      .then((d) => {
        this.setState({
          refresh: false,
        })
      })
    this.setState({
      refresh: false,
    })
  }

  render() {
    const { history, user, path } = this.props
    const { libraries } = this.state

    return (
      <div>
        <Tabs id="uncontrolled-tab-example" defaultActiveKey="blacklist">
          <Tab eventKey="blacklist" title="BlackList">
            <h2>Lista de Blacklist</h2>
            <DataGrid refresh={this.state.refresh} history={history} path={path} />
          </Tab>
          <Tab eventKey="create" title="Anadir a Blacklist">
            {/* COmponent */}
            <h2>Anadir a Blacklist</h2>
            <label>ISSN</label>
            <InputMask
              mask="****-****"
              id="issn"
              style={{ width: "40%" }}
              value={this.state.issn}
              onChange={(e) => this.setState({ issn: e.target.value.toUpperCase() })}
            />

            <label>Titulo</label>
            <InputText
              id="title"
              style={{ width: "40%" }}
              value={this.state.title}
              onChange={(e) => this.setState({ title: e.target.value })}
            />
            <hr />
            <Button
              icon="pi pi-save"
              label="Guardar"
              className="p-button-rounded p-button-success"
              style={{ "margin-bottom": "20px" }}
              onClick={(e) => this.saveBlacklistForm(e)}
            />
            <span>
              <Messages ref={(el) => (this.messages = el)} />
            </span>
          </Tab>

          <Tab eventKey="upload" title="Subir Fichero">
            <h2>Subir Fichero</h2>

            <div style={{ width: "100%", display: "block", "margin-top": "20px" }}>
              <Upload
                user={user}
                libraries={libraries}
                code="blacklist"
                type="text/csv"
              />
            </div>

            <p>
              <hr />
              El sistema acepta ficheros CSV y XLSX, antes de cargar el fichero
              asegúrese de que tiene rellenado todos los campos. Si tiene cualquier
              problema o duda envíe el fichero en excel a cncs@orex.es lo
              verificaremos y nos pondremos en contacto con usted.
            </p>
          </Tab>
        </Tabs>
      </div>
    )
  }
}
export default BlackListTemplate
