import axios from "axios"
import * as types from "./index"
import * as CONFIG from "../config/backend"

export const addLibrary = (params) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({ type: types.ADD_LIBRARY, payload: success })
    return success
  }
  function onError(error) {
    dispatch({ type: "ERROR_GENERATED", error })
    return error
  }
  try {
    const success = await axios.post("http://www...", params)
    return onSuccess(success)
  } catch (error) {
    return onError(error)
  }
}
export const getLibrary = (branchcode) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({ type: types.GET_LIBRARY, payload: success })
    return success
  }
  function onError(error) {
    dispatch({ type: "ERROR_GENERATED", error })
    return error
  }
  try {
    const options = {
      method: "GET",
      url: `//${CONFIG.server.host}:${CONFIG.server.port}/library-data/?library=${branchcode}`,
      headers: {
        mode: "cors",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Basic ${new Buffer(
          `${process.env.REACT_APP_BASIC_AUTH_USER}:${process.env.REACT_APP_BASIC_AUTH_PASS}`
        ).toString("base64")}`,
      },
    }
    const success = await axios(options)
    return onSuccess(success)
  } catch (error) {
    return onError(error)
  }
}
export const deleteLibrary = (group) => async (dispatch) => {
  function onSuccess(success) {
    dispatch({ type: types.GET_LIBRARY, payload: success })
    return success
  }
  function onError(error) {
    dispatch({ type: "ERROR_GENERATED", error })
    return error
  }
  try {
    const options = {
      method: "GET",
      url: `//${CONFIG.server.host}:4000/delete-library/?group=${group}`,
      headers: {
        mode: "cors",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Basic ${new Buffer(
          `${process.env.REACT_APP_BASIC_AUTH_USER}:${process.env.REACT_APP_BASIC_AUTH_PASS}`
        ).toString("base64")}`,
      },
    }
    const success = await axios(options)
    return onSuccess(success)
  } catch (error) {
    return onError(error)
  }
}

export const approveLibrary = () => {}
export const saveLibraryForm = () => {}
export const deleteLibraryUsers = () => {}
export const addLibraryUsers = () => {}
