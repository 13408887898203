import React from "react"
import { Button } from "primereact/button"
import { server } from "../../config/backend"
import { Upload } from "../Forms/Upload"

export const UploadLogo = (props) => {
  const { code, user, logo, action, active, admin, self, isParent } = props
  return (
    <div className="clear" style={{ padding: "1rem" }}>
      {!active && logo && (admin || self || isParent) ? (
        <Button
          icon="pi pi-trash"
          label="Delete logo"
          className="right-float p-button-rounded remove"
          onClick={action}
        />
      ) : (
        ""
      )}

      <img
        alt=""
        src={logo ? `//${server.host}:${server.port}/public/uploads/${logo}` : ""}
      />

      {!logo && (admin || self || isParent) ? (
        <Button
          icon="pi pi-cloud-upload"
          id="add_logo"
          label="Subir logo"
          className="right-float p-button-rounded"
          onClick={props.upload}
        />
      ) : (
        ""
      )}
      {!active && !logo ? (
        <div>
          <Upload code={code} type="image" user={user} />
          <small style={{ padding: "2rem 0" }}>
            El logo debe tener formato jpg o png
          </small>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}
