import { combineReducers } from "redux"
import login from "./loginReducer"
import library from "./libraryReducer"

const rootReducer = combineReducers({
  login,
  library,
})

export default rootReducer
