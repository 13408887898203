import React, { Component } from "react"
import { Button } from "primereact/button"
import { Row, Col } from "react-bootstrap"
import { Messages } from "primereact/messages"
import { ProgressSpinner } from "primereact/progressspinner"

import * as GENERIC from "../../constants/generic"

import { AjaxApiFetch } from "../../Utils/ajaxApiFetch"

export class LoadItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: JSON.parse(localStorage.getItem("user") || '{"error":"true"}'),
      data: [],
      loading: false,
    }
    this.insertAdminHoldings = this.insertAdminHoldings.bind(this)
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user) {
      AjaxApiFetch({
        path: "load-items-data",
      }).then((data) => {
        this.setState({
          data,
        })
      })
    }
    this.setState({
      login: true,
      user,
    })
  }

  insertAdminHoldings() {
    AjaxApiFetch({
      path: "admin-holdings-data",
      method: "POST",
    }).then((data) => {
      if (data.error) {
        this.setState({
          loading: false,
        })
        this.messages.show({
          severity: "error",
          summary: "Fondos",
          closable: false,
          detail: "Fondos pendientes no actualizados",
        })
      }
      this.setState({
        data: [],
        loading: false,
      })
      this.messages.show({
        severity: "success",
        summary: "Fondos",
        closable: false,
        detail: "Fondos pendientes actualizados",
      })
    })
    this.setState({
      loading: true,
    })
  }

  render() {
    const { user, data } = this.state
    const dataMap = data.error ? [] : data
    const isAdmin = user && user.categorycode === GENERIC.ADMIN.TYPE
    if (isAdmin) {
      return (
        <Row>
          <Col xs={12} className="homepage-admin-uploads">
            {dataMap.length > 0 && <h3>Listos a publicar</h3>}

            {dataMap.length > 0 && (
              <Button
                onClick={this.insertAdminHoldings}
                icon="pi pi-upload"
                label="Importar todos"
                className="p-button-rounded"
              />
            )}
            {this.state.loading && <ProgressSpinner />}

            {dataMap.map((i) => (
              <Row key={i.grupo}>
                <Col xs={10}>{i.grupo}</Col>
                <Col xs={2}>{i.contador}</Col>
              </Row>
            ))}
          </Col>
          <Col xs={12}>
            <Messages ref={(el) => (this.messages = el)} />
          </Col>
        </Row>
      )
    }
    return ""
  }
}
