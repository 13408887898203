export const WELCOME = {
  MESSAGE: "Sistema listo para cargar fondos",
  WORD: "Bienvenido",
  SENTENCE: "Tu código de biblioteca es",
}
export const ADMIN = {
  USER: "isciii_admin",
  TYPE: "S",
}
export const COUNTRY = [
  { label: "Aragón", value: "ARAG" },
  { label: "Andalucía", value: "ANDA" },
  { label: "Canarias", value: "CANA" },
  { label: "Cantabria", value: "CANT" },
  { label: "Castilla La Mancha", value: "MANC" },
  { label: "Castilla y León", value: "LEON" },
  { label: "Catalunya", value: "CATA" },
  { label: "Comunidad de Madrid", value: "MADR" },
  { label: "Comunidad Foral de Navarra", value: "NAVA" },
  { label: "Comunitat Valenciana", value: "VALE" },
  { label: "Extremadura", value: "EXTR" },
  { label: "Galicia", value: "GALI" },
  { label: "Illes Balears", value: "BALE" },
  { label: "La Rioja", value: "RIOJ" },
  { label: "País Vasco", value: "VASC" },
  { label: "Principado de Asturias", value: "ASTU" },
  { label: "Región de Murcia", value: "MURC" },
  /*
  { label: "Ciudad Autónoma de Ceuta", value: "CEUT" },
  { label: "Ciudad Autónoma de Melilla", value: "MELI" },
  */
]

export const MAIN_MENU = {}

export const LABELS = {}
