import validator from "validator"

export const HOLDINGS_VALUES = {
  FORM: {
    issn: {
      id: "issn",
      value: "",
      label: "Issn-Isbn",
      visible: true,
      validWhen: true,
      options: {
        case_sensitive: false,
        require_hyphen: true,
        ignore_whitespace: true,
      },
      method: validator.isISSN,
      required: true,
    },
    tipo_fondo: {
      id: "tipo_fondo",
      value: "",
      label: "Tipo de fondo",
      visible: true,
      validWhen: false,
      method: validator.isEmpty,
      required: true,
    },
    titulo: {
      id: "titulo",
      value: "",
      label: "Título de la publicación",
      visible: true,
    },
    fondo: {
      id: "fondo",
      value: "",
      label: "Fondo",
      visible: true,
      validWhen: false,
      method: validator.isEmpty,
      required: true,
    },
    embargo: {
      id: "embargo",
      value: "",
      label: "Embargo",
      visible: true,
    },
    "Paquete-e": {
      id: "Paquete-e",
      value: "",
      label: "Paquete electrónico",
      visible: true,
    },
    barcode: {
      id: "barcode",
      value: "",
      label: "Barcode",
      visible: true,
    },
    biblionumber: {
      id: "biblionumber",
      value: "",
      label: "Bibnumber",
      visible: true,
    },

    id_inc: {
      id: "id_inc",
      value: "",
      label: "Id",
      visible: false,
    },
    id_inc_padre: {
      id: "id_inc_padre",
      value: "1",
      label: "id_inc_padre",
      visible: false,
    },

    tipo_de_carga: {
      id: "tipo_de_carga",
      value: "",
      label: "Tipo de carga",
      visible: true,
    },
    // Issn:
    // tipo_fondo:
    // Titulo
    // Fondo
    // Embargo
    // Paquete-e
  },
  REQUEST: [
    {
      id: "article",
      map: "Title",
      value: "",
      label: "Título del artículo",
    },
    {
      id: "title",
      map: "FullJournalName",
      value: "",
      label: "Título de la publicación",
    },
    {
      id: "autor_lastname",
      map: "LastAuthor",
      value: "",
      label: "Apellido autor",
    },
    {
      id: "author",
      map: "AuthorList",
      value: "",
      label: "Nombre autor",
    },
    {
      id: "year",
      map: "PubDate",
      value: "",
      label: "Año",
    },
    {
      id: "solicit",
      map: "Volume",
      value: "",
      label: "Fascículo de la revista",
    },
    {
      id: "pages_start",
      map: "Pages",
      value: "",
      label: "Página de inicio",
    },
    {
      id: "pages_end",
      map: "Pages",
      value: "",
      label: "Página final",
    },
    {
      id: "place",
      map: "ELocationID",
      value: "",
      label: "Ciudad de publicación",
    },
    {
      id: "editor",
      map: "Source",
      value: "",
      label: "Editor",
    },
    {
      id: "edition",
      map: "RecordStatus",
      value: "",
      label: "Edición",
    },
    {
      id: "issn",
      map: "ISSN",
      value: "",
      label: "Issn-Isbn",
    },
  ],
  TEMPLATE: ["JNL", "EJNL"],
  TEMPLATE_CONSORCIUM: [
    "Solo al centro de compra",
    "Al centro de compra y a todos los centros consorciados",
    "Al centro de compra y selección de centros consorciados",
  ],
}
export default HOLDINGS_VALUES
