import React from "react"
import { Col } from "react-bootstrap"

export const Footer = () => (
  <Col xs={{ span: 9, offset: 2 }}>
    <footer className="footer">
      Instituto de Salud Carlos III. Biblioteca Nacional de Ciencias de la Salud -
      Avda. Monforte de Lemos 5. 28029 Madrid.
      <a href="mailto:cncs@isciii.es">Contacto: cncs@isciii.es</a>
    </footer>
  </Col>
)
