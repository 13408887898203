import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Header } from "./shared/Header"

class LibraryAdded extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Container>
        <Header />
        <Row className="justify-content-md-center">
          <Col xs={12} md={8}>
            <div className="">
              {/* <h1></h1> */}
              <p>
                Su solicitud ha sido registrada correctamente, en breve nos pondremos
                en contacto con usted
              </p>
              <Link to="/library">Añadir Biblioteca</Link>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}
export default LibraryAdded
//
