import React from "react"
import { Row, Col, Nav, Navbar } from "react-bootstrap"

export const Header = ({ branchname, branch_alt_type, branchcity }) => (
  <>
    <Row className="justify-content-md-center header-menu public">
      <Col xs={12} md={12}>
        <Navbar variant="dark" bg="dark" expand="lg">
          <Navbar.Brand href="/library-viewer">
            <img className="left-intro-logo" src="/logo.svg" alt="Logo" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/request-holdings">Solicitar Artículo</Nav.Link>
              <Nav.Link href="/library-viewer">Buscar Biblioteca</Nav.Link>
              <Nav.Link href="/library">Alta Bibliotecas</Nav.Link>
            </Nav>
            <Nav.Link href="/login">Iniciar sesión</Nav.Link>
          </Navbar.Collapse>
        </Navbar>
      </Col>
    </Row>

    <Row className="justify-content-md-center header-wrapper public">
      <Col xs={12} md={10}>
        <h2>{branchname}</h2>
        <p>{branch_alt_type}</p>
        <p>{branchcity}</p>
      </Col>
    </Row>
  </>
)
