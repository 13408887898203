import React, { Component } from "react"
import { Messages } from "primereact/messages"
import { Redirect } from "react-router-dom"
import { AjaxApiFetch } from "../../Utils/ajaxApiFetch"

export class Ping extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
    }
  }

  handleStatus(value) {
    const _this = this
    if (value.message === "Failed to fetch") {
      this.messages.show({
        severity: "error",
        closable: false,
        summary: "Error",
        detail: "No connection to backend",
        life: 25000,
      })
    }

    if (value.code) {
      this.messages.show({
        severity: "error",
        closable: false,
        summary: "Error",
        detail: "No connection to Database",
        life: 25000,
      })
      setTimeout(() => {
        _this.setState({
          redirect: true,
        })
      }, 250)
    }
  }

  componentDidMount() {
    AjaxApiFetch({
      path: "alive",
    })
      .then((data) => {
        this.handleStatus(data)
      })
      .catch((err) => {
        this.handleStatus(err)
      })
  }

  render() {
    const { redirect } = this.state
    return (
      <div>
        {redirect ? (
          <Redirect to="/login" />
        ) : (
          <Messages ref={(el) => (this.messages = el)} />
        )}
      </div>
    )
  }
}
