import * as CONFIG from "../config/backend"

export const AjaxApiFetch = (config) =>
  fetch(
    `${CONFIG.server.protocol + CONFIG.server.host}:${CONFIG.server.port}/${
      config.path
    }${config.group || config.edit || ""}`,
    {
      method: config.method || "GET",
      // mode: 'cors',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Basic ${new Buffer(
          `${process.env.REACT_APP_BASIC_AUTH_USER}:${process.env.REACT_APP_BASIC_AUTH_PASS}`
        ).toString("base64")}`,
      },
      body: config.data ? JSON.stringify(config.data) : null,
    }
  )
    .then((response) => response.json())
    .catch((error) => ({ error }))
