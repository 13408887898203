import React from "react"
import { Row, Col } from "react-bootstrap"

const DataDashboard = ({ combined, holdings }) => {
  const { user } = combined
  const isAdmin = user?.categorycode === "S"
  if (!isAdmin && holdings) {
    return (
      <Row>
        <Col xs={5}>
          <div className="home-dash-wrapper">
            <h3>Fondos de revistas pendientes de revisión y de publicación</h3>
            {holdings.loaded &&
              holdings.pending.rows.map((i, k) => (
                <Row key={`pending-${k}`}>
                  <Col xs={10}>{i["Tipo de Fondo"]}</Col>
                  <Col xs={2}>{i.Valor}</Col>
                </Row>
              ))}
          </div>
        </Col>

        <Col xs={{ span: 5, offset: 2 }}>
          <div className="home-dash-wrapper">
            <h3>Fondos de revistas publicadas</h3>
            {holdings.loaded &&
              holdings.loaded.rows.map((i, k) => (
                <Row key={`loaded-${k}`}>
                  <Col xs={10}>{i["Tipo de Fondo"]}</Col>
                  <Col xs={2}>{i.Valor}</Col>
                </Row>
              ))}
          </div>
        </Col>
        <Col xs={4} />
      </Row>
    )
  }
  return "Cuidado que eres Admin!!! :)"
}
export default DataDashboard
