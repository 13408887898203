import * as types from "../actions"

const defaultState = {}

export default (state = defaultState, action) => {
  const { payload, type } = action

  switch (type) {
    case types.GET_LIBRARY: {
      return payload.data
    }
    case "ADD_LIBRARY": {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
