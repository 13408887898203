import "react-app-polyfill/ie11"
import React from "react"
import ReactDOM from "react-dom"
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom"
import { Provider } from "react-redux"
import * as serviceWorker from "./serviceWorker"
import store from "./store"
import ROUTES from "./Routes"
import "./components/index.scss"

window.store = store

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
function RenderRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={`${route.key}-${i}`} {...route} />
      ))}
      <Route component={() => <Redirect to="/login" />} />
    </Switch>
  )
}
/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} />}
    />
  )
}

const routing = (
  <Provider store={store}>
    <Router>
      <Switch>
        <RenderRoutes routes={ROUTES} />
      </Switch>
    </Router>
  </Provider>
)
ReactDOM.render(routing, document.getElementById("root"))

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
