export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR"
export const LIBRARY_USER_DATA = "LIBRARY_USER_DATA"

// Library
export const GET_LIBRARY = "GET_LIBRARY"
export const ADD_LIBRARY = "ADD_LIBRARY"
export const DELETE_LIBRARY = "DELETE_LIBRARY"
export const UPDATE_LIBRARY = "UPDATE_LIBRARY"
