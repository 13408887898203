import React, { Component } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { ProgressSpinner } from "primereact/progressspinner"
import { Dropdown } from "primereact/dropdown"
import { RadioButton } from "primereact/radiobutton"
import { InputText } from "primereact/inputtext"
import { Dialog } from "primereact/dialog"

import isEmpty from "lodash/isEmpty"
import trim from "lodash/trim"

import { Button } from "primereact/button"
import axios from "axios"
import XML from "react-xml-parser"
import { Messages } from "primereact/messages"
import { AjaxApiFetch } from "../../Utils/ajaxApiFetch"
import { removeDiacritics } from "../../Utils/removeDiacritics"

import * as HOLDINGS from "../../constants/holdings"
import * as GENERIC from "../../constants/generic"
import { sortAlpha } from "../../Utils/sort"
import { locale } from "../../constants/language.config.js"

const country = sortAlpha(GENERIC.COUNTRY).concat([{ label: "Todas", value: "ALL" }])

const SendButton = ({
  software,
  selected,
  active,
  vistLink,
  setReplyEmail,
  sendMail,
}) => {
  return software ? (
    <Button
      icon="pi pi-link"
      label="Visita el enlace"
      disabled={!active}
      className="p-button-rounded p-button-success"
      onClick={(e) => vistLink(e)}
    />
  ) : (
    <>
      <RenderReplyEmailField
        selected={selected}
        active={active}
        setReplyEmail={setReplyEmail}
      />
      <Button
        icon="pi pi-envelope"
        label="Enviar"
        disabled={!active || !selected}
        className="p-button-rounded p-button-success"
        onClick={(e) => sendMail(e)}
      />
      <hr />
    </>
  )
}

class RenderReplyEmailField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  render() {
    const { visible } = this.state
    const { setReplyEmail, active, selected } = this.props

    return (
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          width: "fit-content",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <>
          {selected && (
            <>
              <label>Añadir "Email de respuesta:"</label>
              <RadioButton
                value={visible}
                checked={visible}
                onChange={(e) =>
                  this.setState({
                    visible: !visible,
                  })
                }
              />
            </>
          )}
          {visible && selected && (
            <div style={{ padding: "20px" }}>
              <InputText
                id="reply-to-email"
                type="email"
                placeholder=""
                disabled={!active || !selected}
                onChange={setReplyEmail}
              />
            </div>
          )}
        </>
        <br />
      </div>
    )
  }
}

export class FormRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      branchcity: "",
      value: [],
      fields: HOLDINGS.HOLDINGS_VALUES.REQUEST,
      message: null,
      selected: "",
      active: false,
      loading: false,
      visible: false,
      replyEmail: null,
      email: "",
      emailInfo: {},
    }

    this.textYearInput = React.createRef()
    this.focusTextYearInput = this.focusTextYearInput.bind(this)

    this.onCityChange = this.onCityChange.bind(this)
    this.onDateSearch = this.onDateSearch.bind(this)
    this.setCoutryChange = this.setCoutryChange.bind(this)
    this.buildTemplateRadioButton = this.buildTemplateRadioButton.bind(this)
    this.yearFilter = this.yearFilter.bind(this)
    this.handleFormValues = this.handleFormValues.bind(this)
    this.searchForm = this.searchForm.bind(this)
    this.buildRequest = this.buildRequest.bind(this)
    this.setReplyEmail = this.setReplyEmail.bind(this)
    this.sendMail = this.sendMail.bind(this)
    this.vistLink = this.vistLink.bind(this)
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search)
    const grupo = params.get("grupo") || null
    const rawRequest = localStorage.getItem("request")
    const fields = HOLDINGS.HOLDINGS_VALUES.REQUEST

    AjaxApiFetch({
      path: "library-software",
    }).then((data) => {
      this.setState({
        software_types: [{ label: "Sin Software", value: 0 }, ...(data || {})],
      })
    })

    let request = {}
    if (rawRequest) {
      try {
        request = JSON.parse(rawRequest)
        this.setState(
          () => {
            return { ...request }
          },
          () => localStorage.clear("request")
        )
      } catch (error) {
        this.setState({
          fields: [...fields],
        })
      }
    } else {
      this.setState({
        fields: [...fields],
      })
    }
    if (grupo) this.buildRequest(false, null)
  }

  buildRequest(isIssn, value, grupo) {
    const { fields } = this.state
    const params = new URLSearchParams(window.location.search)
    const isGrupo = !!params.get("grupo") && !grupo

    const groupLib = isGrupo ? params.get("grupo") : grupo

    const biblionumber = value || params.get("biblionumber")
    const title = value

    AjaxApiFetch({
      path: isGrupo ? "request-make" : "request-pmid-make",
      group: isGrupo
        ? `?biblionumber=${biblionumber}&grupo=${groupLib}`
        : `?&biblionumber=${isIssn}${title ? `&title=${title}` : ""}${
            grupo ? `&grupo=${grupo}` : ""
          }`,
    }).then((data) => {
      if (data.error) {
        this.setState({
          selected: null,
          empty: true,
          data: null,
          email: null,
          visible: true,
          active: true,
          message: {
            header: `${locale["No records found"]}`,
            message: `${locale["Empty Request"]}`,
          },
        })

        this.messages.show({
          severity: "error",
          summary: locale["Empty Request"],
          closable: false,
        })

        return
      }

      const rows = Object.values(data.data).sort(function (x, y) {
        return x === y ? 0 : x ? 1 : -1
      })
      const homebranch = rows[0] ? rows[0].homebranch : ""

      const selected = groupLib || homebranch

      let title
      let issn

      const formValues =
        selected && data.holding
          ? fields.map((i) => {
              i.value = data.holding[i.id]
              if (i.id === "title") title = data.holding[i.id]
              if (i.id === "issn") issn = data.holding[i.id]
              return i
            })
          : null
      if (rows.length <= 0) {
        return
      }
      const central = (() => {
        try {
          return JSON.parse(localStorage.getItem("central"))
        } catch (error) {
          return []
        }
      })()

      const avalilableCentres =
        rows.filter((centre) => {
          if (central) {
            return central.find((lib) => {
              return lib.branch_alt_code === centre.homebranch
            })
          }
          return rows
        }) || []

      if (data.is_center && data.is_center !== "SI") {
        const revised = avalilableCentres.filter((item) => !item.selected)
        let avalilable = []
        avalilable = revised

        if (avalilable.length) avalilable[0].selected = true
        this.setState({
          data: avalilable,
          empty: false,
          software: data.has_software === "SI",
          centre: data.is_center === "SI",
          url: `${data.url}?title=${title}&issn=${issn}`,
          biblio: biblionumber,
          group: groupLib,
          email: data.email,
          selectedData: avalilable.length ? avalilable[0] : null,
          selected: avalilable.length ? avalilable[0].homebranch : null,
          fields: formValues || [...fields],
          active: true,
          visible: true,
          message: {
            header: `${locale["No holdings found in centre"]}`,
            message: `${locale["No holdings found in centre description"]}`,
          },
        })
      } else {
        this.setState({
          data: avalilableCentres,
          empty: false,
          software: data.has_software === "SI",
          centre: data.is_center === "SI",
          url: `${data.url}?title=${title}&issn=${issn}`,
          biblio: biblionumber,
          group: groupLib,
          email: data.email,
          selected,
          selectedData: avalilableCentres.length ? avalilableCentres[0] : null,

          fields: formValues || [...fields],
          active: true,
          visible: true,
          message: null,
        })
      }
      if (this.state.selectedData) this.handleRadioButton(homebranch || grupo, null)
    })
  }

  focusTextYearInput() {
    setTimeout(() => {
      if (this.textYearInput.current) this.textYearInput.current.element.focus()
    }, 500)
  }

  handleRadioButton(homebranch, branchemail) {
    const { software_types } = this.state

    AjaxApiFetch({
      path: "library-data",
      group: `/?library=${homebranch}`,
    }).then((data) => {
      if (data.error) {
        return
      }
      const [{ library, users }] = data || null
      /*
        Request - C17 send Email
      */
      const validSoftware =
        !isEmpty(library.branch_alt_url_form_loan) &&
        !library.branch_alt_url_form_loan.includes("c17") &&
        +library.branch_alt_softloan !==
          software_types.find(({ label }) => label === "ILL17").value
      /*
        Request - C17 send Email
      */
      const validUserEmail = users.find((user) => user.tipo_resp === "Prestamo")
      const validUserLibrary = users.find((user) => user.tipo_resp === "Biblioteca")

      let formEmail = ""
      let emailInfo = {}
      // Rules, Valid Prestamo, Biblioteca or general emails
      if (validUserEmail) {
        formEmail = validUserEmail.resp_mail
        emailInfo = validUserEmail
      } else if (validUserLibrary) {
        formEmail = validUserLibrary.resp_mail
        emailInfo = validUserLibrary
      } else {
        formEmail = branchemail
        emailInfo = branchemail
      }

      this.setState({
        email: formEmail,
        emailInfo,
        software: validSoftware && library.branch_alt_softloan !== "NO",
        url: library.branch_alt_url_form_loan || null,
        selected: homebranch,
        active: true,
      })
    })
  }

  buildTemplateRadioButton(rowData, column) {
    return (
      <RadioButton
        value={rowData.homebranch}
        data-email={rowData.branchemail}
        id={rowData.homebranch}
        checked={this.state.selected === rowData.homebranch}
        onChange={(e) =>
          this.handleRadioButton(rowData.homebranch, rowData.branchemail)
        }
      />
    )
  }

  buildTemplateLink(rowData, column) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`/home/libraries/${rowData.homebranch}`}
      >
        {rowData.branchname}
      </a>
    )
  }

  buildItype(rowData, column) {
    return (
      <span style={{ "word-break": "break-all" }}>
        {rowData.itype && rowData.itype.toString().replace(/,/g, " & ")}
      </span>
    )
  }

  buildCountry(rowData, column) {
    return (
      <span style={{ "word-break": "break-all" }}>
        {country.filter((i) => i.value === rowData.branchcountry)[0].label}
      </span>
    )
  }

  buildEnumchron(rowData, column) {
    return rowData.itype.map((i, k) => {
      switch (i) {
        case "JNL":
          return (
            <span className="enumchron-col" style={{ color: "green" }}>
              {i} :<span className="enumchron-col">{rowData.enumchron[k]}</span>{" "}
              {k < rowData.itype.length - 1 && "&"}{" "}
            </span>
          )
        case "EJNL":
          return (
            <span className="enumchron-col" style={{ color: "blue" }}>
              {i} :<span className="enumchron-col">{rowData.enumchron[k]}</span>{" "}
              {k < rowData.itype.length - 1 && "&"}{" "}
            </span>
          )
        default:
          return (
            <span className="enumchron-col" style={{ color: "red" }}>
              {i} :<span className="enumchron-col">{rowData.enumchron[k]}</span>{" "}
              {k < rowData.itype.length - 1 && "&"}{" "}
            </span>
          )
      }
    })
  }

  vistLink() {
    const { url } = this.state
    window.open(url)
  }

  sendMail() {
    const { fields, biblio, email, selected, software, replyEmail } = this.state
    const { library, user, history } = this.props

    if (!user) {
      const state = JSON.stringify(this.state)
      localStorage.setItem("request", state)
      history.push({
        pathname: "/login",
        state: {
          prevlocation: {
            pathname: "/home/request-holdings",
          },
        },
      })

      return false
    }
    window.scrollTo(0, 0)

    if (selected && !software && user.categorycode !== "S") {
      AjaxApiFetch({
        path: "request-mail",
        method: "POST",
        group: `?biblionumber=${biblio}&grupo=${selected}&email=${email}&library=${library}&replyEmail=${
          replyEmail || null
        }`,
        data: fields,
      }).then((data) => {
        this.messages.show({
          severity: "success",
          summary: `Email enviado correctamente a ${selected}`,
          closable: false,
          detail: "Email enviado",
        })

        this.setState({
          visible: false,
          selected: false,
        })
      })
    } else {
      this.messages.show({
        severity: "error",
        summary: "Error",
        closable: false,
        detail: "Elejir Biblioteca",
      })
    }
  }

  searchForm() {
    const { fields } = this.state
    const params = new URLSearchParams(window.location.search)
    const grupo = params.get("grupo") || null
    const value = fields
      .filter((i) => i.id === "issn" || i.id === "title")
      .map((i) => i.value)
    const issnInput = document.getElementById("issn")
    if (!isEmpty(value[0]) || !isEmpty(value[1])) {
      this.buildRequest(value[1], value[0], grupo)
    } else {
      issnInput.focus()

      this.messages.show({
        severity: "error",
        summary: "Error",
        closable: false,
        detail: locale["Empty Request"],
      })

      this.setState({
        visible: true,
        active: true,
        message: {
          header: `${locale["No records found"]}`,
          message: `${locale["Empty Request"]}`,
        },
      })
      return false
    }
  }

  searchClearForm() {
    const { fields } = this.state
    this.setState({
      fields: [
        ...fields.map((i) => {
          i.value = ""
          return i
        }),
      ],
      selected: "",
      emailInfo: {},
      data: null,
    })
  }

  handleFormValues(ev) {
    const { fields } = this.state
    const { id } = ev.target
    const { value } = ev.target

    this.setState({
      fields: fields.map((i) => {
        if (i.id === id) i.value = value
        return i
      }),
    })
  }

  searchDOI() {
    const params = new URLSearchParams(window.location.search)
    const grupo = params.get("grupo") || null

    const { doi_search, fields } = this.state
    const doiSearchInput = document.getElementById("doi_value")

    if (!doi_search || isEmpty(trim(doi_search))) {
      doiSearchInput.focus()
      return false
    }

    AjaxApiFetch({
      path: "doi-search",
      method: "GET",
      group: `?doi=${trim(doi_search)}`,
    })
      .then((data) => {
        if (data.error) {
          this.messages.show({
            severity: "error",
            summary: "Error",
            closable: false,
            detail: "Error en el Pedido",
          })

          this.setState({
            data: [],
            selected: null,
            active: false,
            email: null,
          })

          return
        }
        const string = data.response

        const values = string.split("},")

        const entry = {}

        values.forEach((item) => {
          if (item.includes(" @article")) {
            const index = item.lastIndexOf("{")
            const string = item.substr(index + 1)

            entry.article = string || ""
          }
          if (item.includes("ISSN")) {
            const index = item.indexOf("{")
            const string = item.substr(index + 1)

            entry.issn = string || ""
          }
          if (item.includes("year")) {
            const index = item.indexOf("{")
            const string = item.substr(index + 1)

            entry.year = string || ""
          }
          if (item.includes("journal")) {
            const index = item.indexOf("{")
            const string = item.substr(index + 1)

            entry.journal = string || ""
          }
          if (item.includes("publisher")) {
            const index = item.indexOf("{")
            const string = item.substr(index + 1)
            entry.publisher = string || ""
          }
          if (item.includes("author")) {
            const index = item.indexOf("{")
            const string = item.substr(index + 1)
            entry.author = string || ""
          }
        })

        this.setState({
          date: entry.year,
          fields: fields.map((field) => {
            if (field.id === "issn") field.value = entry.issn
            if (field.id === "editor") field.value = entry.publisher
            if (field.id === "title") field.value = entry.journal
            if (field.id === "year") field.value = entry.year
            if (field.id === "article") field.value = entry.article
            if (field.id === "author") field.value = entry.author
            if (field.id === "edition") field.value = entry.year
            return field
          }),
          loading: false,
        })

        this.buildRequest(entry.issn, "", grupo)
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        this.focusTextYearInput()
      })

    this.setState({
      loading: true,
    })
  }

  getPMID(event) {
    const params = new URLSearchParams(window.location.search)
    const grupo = params.get("grupo") || null
    const doiInput = document.getElementById("pmid_value")
    const pmid = trim(doiInput.value)
    const { fields } = this.state

    if (isEmpty(trim(pmid))) {
      doiInput.focus()
      return false
    }

    this.setState({
      loading: true,
    })

    axios
      .get(
        `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&id=${pmid}`
      )
      .then((response) => {
        const result = new XML().parseFromString(response.data)
        const children = result.children.pop()
        const children_values = children.children.pop()
        const final = children_values.children

        if (final.length > 0) {
          const mapped = final.map((i) => {
            let values = []
            if (i.children.length > 0) values = i.children.map((i) => i.value)
            return {
              id: i.name,
              type: i.attributes.Name,
              value: i.value.length > 0 ? i.value : values.toString(),
            }
          })
          const results = fields.map((i) => {
            mapped.filter((item) => {
              if (item.type === i.map) {
                i.value = item.value
                return i
              }
              return false
            })
            return i
          })
          const dateString = results[4] && (results[4].value || "")
          const dates = dateString.length && dateString.match(/\b(19|20)\d{2}\b/)
          const date = dates && (dates[0] || "")

          this.setState({
            fields: results,
            date,
            loading: false,
          })
          const issn = fields && fields.filter((i) => i.id === "issn").pop().value
          const title = fields && fields.filter((i) => i.id === "title").pop().value

          if (grupo) {
            this.buildRequest(issn, title, grupo)
          } else {
            this.buildRequest(issn, title, null)
          }
        } else {
          this.setState({
            fields: fields.map((i) => {
              i.value = ""
              return i
            }),
            data: [],
            selected: null,
            active: false,
            email: null,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        this.focusTextYearInput()
      })
  }

  onCityChange(event) {
    this.dt.filter(event.target.value, "branchcity", "equals")
    this.setState({ branchcity: event.target.value })
  }

  onDateSearch(ev) {
    this.setState({ date: ev.target.value })
  }

  setCoutryChange(ev) {
    const branchcountry = ev.value
    this.setState({ branchcountry })
    if (ev.value === "ALL") this.dt.filter(null, "branchcountry", "equals")
    else this.dt.filter(ev.value, "branchcountry", "equals")
  }

  yearFilter(value, year) {
    const comma = value.filter((i) => {
      const years = i.split(",")
      if (years.length > 0) return years.map((y) => y === year).includes(true)
      return false
    })
    // case Parentisis
    const parentisis = value.map((i) => {
      const item = i.match(/\(([^)]+)\)/g)
      if (!item) return false
      return item.map((e) => {
        if (e === year) return true

        const element = e.replace("(", "").replace(")", "")

        if (element.includes("-")) {
          let y1 = element.split("-")[0]
          let y2 = element.split("-")[1]

          if (y1 && y1.length <= 2) y1 = +y1 <= 99 ? `19${y1}` : `20${y1}`
          if (y2 && y2.length <= 2) {
            y2 = +y2 <= 99 ? `19${y2}` : `20${y2}`
          }

          if (y1 && y1.length <= 2) y1 = +y1 <= 99 ? `19${y1}` : `20${y1}`
          if (y2 && y2.length <= 2) {
            y2 = +y2 <= 99 ? `19${y2}` : `20${y2}`
          }
          return +y1 && +y1 <= +year && y2 && +y2 >= +year
        }
        return false
      })
    })

    // case hifen
    const hifen = value.map((i) => {
      let interval
      if (i.includes("-")) {
        interval = i.split("-")
      } else {
        return false
      }

      if (interval.length <= 1) {
        if (interval[0].includes(year)) {
          return true
        }
        return false
      }
      if (interval.length > 1) {
        let y1 = interval[0]
        let y2 = interval[1]

        y1 = y1.includes("(") ? y1.split("(")[1] : y1
        y2 = y2.includes(")") ? y2.split(")")[0] : y2

        if (y1 && y1.length <= 2) y1 = +y1 <= 99 ? `19${y1}` : `20${y1}`
        if ((y2 && y2.length <= 2) || (y2.length <= 3 && y2.includes("."))) {
          y2 = `${y1[0]}${y1[1]}${y2}`
        }

        if (y2 === "") return +y1 && +y1 <= +year
        return +y1 && +y1 <= +year && y2 && +y2 >= +year
      }
      return false
    })

    const a = hifen.includes(true)
    const b = parentisis.includes(true)
    const c = comma.includes(true)
    const results = [a, b, c]
    return results.includes(true)
  }

  renderMessage() {
    const { message, visible } = this.state
    const params = new URLSearchParams(window.location.search)
    const isGrupo = !!params.get("grupo")

    if (!message || isGrupo) return <div />
    return (
      <Dialog
        header={message.header}
        visible={visible}
        style={{ width: "50vw" }}
        modal
        onHide={() => this.setState({ visible: false })}
      >
        <p>{message.message}</p>
      </Dialog>
    )
  }

  setReplyEmail({ target }) {
    this.setState({
      replyEmail: target.value,
    })
  }

  renderResults() {
    const { empty, data, software, selected, email, emailInfo } = this.state

    function filterFunction(value, filter) {
      const v = removeDiacritics(value.toLowerCase())
      const f = removeDiacritics(filter.toLowerCase())

      if (v.includes(f)) return true
      return false
    }

    function filterCode(value, filter) {
      if (value.toUpperCase().includes(filter.toUpperCase())) return true
      return false
    }

    const countryFilter = (
      <Dropdown
        style={{ width: "100%" }}
        className="ui-column-filter"
        value={this.state.branchcountry}
        options={country}
        onChange={this.setCoutryChange}
      />
    )

    const header = (
      <div style={{ textAlign: "left" }}>
        <div
          style={{
            float: "left",
            width: "17.5%",
            marginRight: "2.5%",
            marginBottom: "20px",
          }}
        >
          <i
            className="pi pi-search"
            style={{ margin: "4px 4px 0 0", display: "inline" }}
          />
          <label>Año</label>
          <InputText
            type="search"
            ref={this.textYearInput}
            onInput={(e) => {
              this.setState({ date: e.target.value })
              return this.dt.filter(e.target.value, "enumchron", "custom")
            }}
            onFocus={(e) => this.dt.filter(e.target.value, "enumchron", "custom")}
            placeholder="Año"
            value={this.state.date}
            size="50"
          />
        </div>
        <div style={{ float: "left", width: "17.5%", marginRight: "2.5%" }}>
          <label>Tipo de Fondo</label>
          <InputText
            type="search"
            onInput={(e) => this.dt.filter(e.target.value, "itype", "startsWith")}
            placeholder="Tipo de Fondo"
            size="50"
          />
        </div>
        <div style={{ float: "left", width: "17.5%", marginRight: "2.5%" }}>
          <label>Código de la biblioteca</label>
          <InputText
            type="search"
            onInput={(e) => {
              this.dt.filter(e.target.value, "homebranch", "custom")
            }}
            placeholder="Code"
            size="50"
          />
        </div>
        <div style={{ float: "left", width: "17.5%", marginRight: "2.5%" }}>
          <label>CCAA</label>
          <Dropdown
            style={{ width: "100%" }}
            className="ui-column-filter"
            value={this.state.branchcountry}
            options={country}
            onChange={this.setCoutryChange}
          />
        </div>
        <div style={{ float: "left", width: "17.5%", marginRight: "0" }}>
          <label>Ciudad</label>
          <InputText
            type="search"
            onInput={(e) => {
              this.dt.filter(e.target.value, "branchcity", "custom")
            }}
            placeholder="Ciudad"
            size="50"
          />
        </div>
      </div>
    )
    if (empty) {
      return (
        <span className="error">
          {this.renderMessage()}
          {locale["empty results"]}
        </span>
      )
    } else {
      return (
        <div>
          {selected && (
            <div>
              <div>
                <strong style={{ fontWeight: "900" }}>Responsable: </strong>
                {emailInfo && emailInfo.resp}
              </div>
              <p>
                <strong style={{ fontWeight: "900" }}>Email: </strong>
                {emailInfo && emailInfo.resp_mail}
              </p>
              <p>
                {" "}
                <strong style={{ fontWeight: "900" }}>Teléfono: </strong>
                {emailInfo && emailInfo.resp_tel}
              </p>
              Envio de la solicitud a: <a href={`mailto:${email}`}>{email}</a>{" "}
              <b>{`(${selected})`}</b>
            </div>
          )}

          {software ? (
            <p>
              {selected} {locale["software message"]}
            </p>
          ) : (
            ""
          )}

          <SendButton
            software={software}
            selected={this.state.selected}
            active={this.state.active}
            vistLink={this.vistLink}
            sendMail={this.sendMail}
            setReplyEmail={this.setReplyEmail}
          />
          <br />
          <br />

          <br />

          <h3>{locale["filter results"]}</h3>

          <DataTable
            ref={(el) => (this.dt = el)}
            value={data}
            paginator
            rows={50}
            rowsPerPageOptions={[50, 100, 200]}
            header={header}
          >
            <Column
              field="itemnumber"
              header=""
              filter={false}
              sortable
              body={this.buildTemplateRadioButton}
            />
            <Column
              field="branchname"
              header="Biblioteca"
              body={this.buildTemplateLink}
              sortable
            />

            <Column
              field="homebranch"
              header="Código"
              sortable
              filterFunction={filterCode}
            />
            <Column
              field="branchcity"
              header="Ciudad"
              filterFunction={filterFunction}
            />
            <Column
              field="branchcountry"
              header="CCAA"
              body={this.buildCountry}
              filterElement={countryFilter}
            />
            <Column field="itype" header="Tipo de Fondo" body={this.buildItype} />
            <Column
              field="enumchron"
              header="Fondos"
              body={this.buildEnumchron}
              sortable
              filterMatchMode="custom"
              filterFunction={this.yearFilter}
            />
          </DataTable>

          {selected && (
            <div>
              <p>
                Envio de la solicitud a: <a href={`mailto:${email}`}>{email}</a>{" "}
                <b>{`(${selected})`}</b>
              </p>
            </div>
          )}

          {software ? (
            <p>
              {selected} {locale["software message"]}
            </p>
          ) : (
            ""
          )}

          <SendButton
            software={software}
            selected={this.state.selected}
            active={this.state.active}
            vistLink={this.vistLink}
            sendMail={this.sendMail}
            setReplyEmail={this.setReplyEmail}
          />
          {this.renderMessage()}
        </div>
      )
    }
  }

  render() {
    const { fields } = this.state

    return (
      <div>
        <h2>Solicitar Artículo</h2>

        <Messages ref={(el) => (this.messages = el)} />

        <h3>Puede buscar publicaciones por PMID / DOI</h3>

        <span className="pmid-wrapper">
          <label>Recuperar metadatos por PMID</label>
          <InputText
            id="pmid_value"
            type="number"
            placeholder="ej: 19329464"
            size="50"
            onKeyPress={(e) => e.charCode === 13 && this.getPMID(e)}
          />
          <Button
            icon="pi pi-search"
            label="Recuperar metadatos"
            className="p-button-rounded p-button-success"
            onClick={(e) => this.getPMID(e)}
          />
        </span>

        <span className="pmid-wrapper">
          <label>Recuperar metadatos por DOI</label>
          <InputText
            id="doi_value"
            type="text"
            placeholder="ej: 10.1002/ajmg.c.31387"
            size="50"
            onChange={({ target }) =>
              this.setState({
                doi_search: target.value,
              })
            }
          />
          <Button
            icon="pi pi-search"
            label="Recuperar DOI"
            className="p-button-rounded p-button-success"
            onClick={(e) => this.searchDOI(e)}
          />
        </span>

        {this.state.loading && (
          <p>
            <br /> A cargar datos
            <ProgressSpinner />
          </p>
        )}

        <hr />

        <h3>Puede buscar publicaciones por Título de la publicación y Issn-Isbn</h3>
        {fields &&
          fields.map((i) => (
            <label key={i.label} className="label-wrapper">
              {i.label}
              <InputText
                id={i.id}
                type="text"
                placeholder=""
                onChange={(e) => this.handleFormValues(e)}
                size="50"
                value={i.value}
              />
            </label>
          ))}

        <div>
          <Button
            icon="pi pi-search"
            label="Buscar"
            className="p-button-rounded p-button-success"
            onClick={(e) => this.searchForm(e)}
          />{" "}
          <Button
            icon="pi pi-search"
            label="Limpiar"
            className="p-button-rounded p-button-success"
            onClick={(e) => this.searchClearForm(e)}
          />
          <hr />
        </div>

        {this.renderResults()}
      </div>
    )
  }
}
