import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import { DataGrid } from "../../DataGrid"
import { LoadItems } from "../../Admin/LoadItems"
import { Button } from "primereact/button"
import { TabView, TabPanel } from "primereact/tabview"

import { ProgressSpinner } from "primereact/progressspinner"
import { DataDashboard } from "./"

import { AjaxApiFetch } from "Utils/ajaxApiFetch"

class RefreshHoldings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: "",
    }
  }

  refreshHoldings() {
    this.setState({ loading: true })

    AjaxApiFetch({
      path: "holdings-refresh",
    }).then((data) => {
      this.setState({
        message: data,
        loading: false,
      })
    })
  }

  render() {
    const { message } = this.state
    const { isAdmin } = this.props
    return (
      <>
        {isAdmin && (
          <Button
            icon="pi pi-refresh"
            label="Refresh Holdings"
            className="p-button-rounded remove"
            data-title={"Refresh Holdings"}
            id={"refresh"}
            onClick={() => this.refreshHoldings()}
          />
        )}
        {this.state.loading && <ProgressSpinner />}
        <p className="success-message">
          {message.response && "Holdings actualizadas correctamente."}
        </p>
        <p className="error">{message.error && "Error - " + message.error}</p>
      </>
    )
  }
}

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeInde: 0,
    }
  }

  render() {
    const { user, group, history, holdings } = this.props
    const isAdmin = user && user.categorycode === "S"
    const isParent = user?.is_parent
    return (
      <Row>
        <Col xs={12}>
          {isAdmin && <RefreshHoldings isAdmin={isAdmin} />}

          <h2>Sistema listo para cargar fondos</h2>
          <p>
            Bienvenido {user?.branch && user.branch.branchname} Tu código de
            biblioteca es: {user?.branch && user.branchcode}{" "}
          </p>

          {isAdmin && <LoadItems />}

          {!isAdmin && (
            <TabView
              activeIndex={this.state.activeIndex}
              onTabChange={(e) => this.setState({ activeIndex: e.index })}
            >
              <TabPanel header="Resumen de Fondos">
                <DataDashboard holdings={holdings} combined={this.props} />
              </TabPanel>
              <TabPanel header={isParent && !isAdmin ? "Gestión de la Red" : ""}>
                {isParent && !isAdmin && (
                  <>
                    <h2>Grupos con permiso de lectura</h2>
                    <DataGrid
                      history={history}
                      user={user}
                      path="library-relationships"
                      group={group}
                    />
                  </>
                )}
              </TabPanel>
            </TabView>
          )}
        </Col>
      </Row>
    )
  }
}
export default Home
