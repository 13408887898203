import React, { Component } from "react"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import moment from "moment"

const es = {
  firstDayOfWeek: 1,
  dayNames: [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ],
  dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  monthNames: [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ],
  monthNamesShort: [
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic",
  ],
  today: "Hoy",
  clear: "Limpiar",
  dateFormat: "dd/mm/yy",
  weekHeader: "Sm",
}
export class AlertForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this.props,
      processing: false,
    }
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.library.branch_alt_alter_fecha !==
      this.props.library.branch_alt_alter_fecha
    ) {
      this.setState({
        ...this.props,
      })
    }
  }

  updateAlertContent(e) {
    const { library } = this.state
    const { value } = e.target
    library[e.target.id] = value
    this.setState({
      ...library,
    })
  }

  render() {
    const { library } = this.state
    const { saveAlertContent, isAdmin, isParent, isSelf, isPublic, isAdd } =
      this.props
    const { branch_alt_close_alert, branch_alt_alter_fecha } = library
    const date =
      branch_alt_alter_fecha && branch_alt_alter_fecha.length
        ? new Date(moment(branch_alt_alter_fecha, "DD/MM/YYYY"))
        : branch_alt_alter_fecha

    const today = new Date()
    return (
      <div className="form-data-wrapper">
        <div className="input-data-wrapper">
          <label>Texto de la alerta</label>
          <div className="input-data-wrapper-input">
            {(isAdmin || isSelf || isParent || isAdd) && !isPublic ? (
              <InputText
                className="input-data-wrapper"
                id="branch_alt_close_alert"
                value={branch_alt_close_alert}
                onChange={(e) => this.updateAlertContent(e)}
                disabled={isPublic}
                style={{
                  borderColor: !isPublic ? "inherit" : "transparent",
                  color: isPublic ? "#333" : "gray",
                }}
              />
            ) : (
              <span style={{ fontSize: "14px", textTransform: "capitalize" }}>
                {date > today ? branch_alt_close_alert : ""}
              </span>
            )}
          </div>
        </div>
        <div className="input-data-wrapper">
          <label>Fecha fin de alerta (dd/mm/aaaa)</label>

          <div className="input-data-wrapper-input">
            {(isAdmin || isSelf || isParent || isAdd) && !isPublic ? (
              <Calendar
                locale={es}
                dateFormat="dd/mm/yy"
                id="branch_alt_alter_fecha"
                value={date}
                onChange={(e) => this.updateAlertContent(e)}
                monthNavigator={true}
                yearNavigator={true}
                yearRange="2010:2030"
                disabled={isPublic}
                style={{
                  borderColor: !isPublic ? "inherit" : "transparent",
                  color: isPublic ? "#333" : "gray",
                }}
              />
            ) : (
              <span style={{ fontSize: "14px", textTransform: "capitalize" }}>
                {date > today ? moment(date).format("DD/MM/YYYY") : ""}
              </span>
            )}
          </div>
        </div>
        <hr />
        {(isAdmin || isParent || isSelf) && (
          <Button
            icon="pi pi-exclamation-triangle"
            label="Guardar Alertas"
            className="right-float p-button-rounded"
            onClick={(e) => saveAlertContent(library)}
          />
        )}
      </div>
    )
  }
}
