import React from "react"
import { createBrowserHistory } from "history"

import Login from "./containers/Login"
import Home from "./containers/Main"
import Logout from "./containers/Logout"
import { PublicAddLibraryFrom } from "./containers/PublicAddLibraryFrom"
import { PublicViewLibrary } from "./containers/PublicViewLibrary"
import { PublicSearchLibrary } from "./containers/PublicSearchLibrary"
import LibraryAdded from "./containers/LibraryAdded"
import { PublicRequestHoldingsForm } from "./containers/PublicRequestHoldingsForm"

const history = createBrowserHistory()

const ROUTES = [
  {
    path: "/login",
    exact: true,
    component: (props) => <Login {...props} />,
  },
  {
    path: "/logout",
    exact: true,
    component: () => <Logout />,
  },
  {
    path: "/inserted",
    exact: true,
    component: () => <LibraryAdded />,
  },
  {
    path: "/library-viewer/:library",
    exact: true,
    component: (props) => <PublicViewLibrary history={history} {...props} />,
  },
  {
    path: "/library",
    exact: true,
    component: (props) => <PublicAddLibraryFrom history={history} />,
  },
  {
    path: "/library-viewer",
    exact: true,
    component: (props) => <PublicSearchLibrary history={history} {...props} />,
  },
  {
    path: "/request-holdings",
    exact: true,
    component: (props) => <PublicRequestHoldingsForm {...props} />,
  },
  {
    path: "/home",
    component: (props) => <Home {...props} />,
  },
]
export default ROUTES
