// import validator from 'validator'

// Libraries
export const REQUEST_VALUES = {
  FORM: [
    {
      id: "issn",
      value: "",
      label: "Issn-Isbn",
      visible: true,
    },
    {
      id: "tipo_fondo",
      value: "",
      label: "Tipo de fondo",
      visible: true,
    },
    {
      id: "titulo",
      value: "",
      label: "Título de la publicación",
      visible: true,
    },
    {
      id: "fondo",
      value: "",
      label: "Fondo",
      visible: true,
    },
    {
      id: "embargo",
      value: "",
      label: "Embargo",
      visible: true,
    },
    {
      id: "Paquete_e",
      value: "",
      label: "Paquete electrónico",
      visible: true,
    },
    {
      id: "solicit",
      value: "",
      label: "Solitud",
      visible: true,
    },
    // { id : "id_inc", value: '', label: 'Email'},
    { id: "id_inc_padre", value: "", label: "id_inc_padre" },
    {
      id: "tipo_de_carga",
      value: "",
      label: "Tipo de carga",
      visible: true,
    },
  ],
  REQUEST_COMBO: [
    { label: "Pendiente", value: 0 },
    { label: "Enviada", value: 1 },
    { label: "Recibida", value: 2 },
  ],
}
export default REQUEST_VALUES
