import React, { Component } from "react"
import { Row, Col } from "react-bootstrap"
import { AnimatedSwitch, spring } from "react-router-transition"
import { Route, withRouter, Redirect } from "react-router-dom"
import * as GENERIC from "../../constants/generic"
import { DataGrid } from "../DataGrid"
import { DataGridGroups } from "../Groups"
import Library from "../Library"
import { FormRequest } from "../Requests/FormRequest"
import HoldingsTemplate from "../Holdings/HoldingsTemplate"
import HoldingsTemplateParent from "../Holdings/HoldingsTemplateParent"
import BlackListTemplate from "../Holdings/BlackListTemplate"
import IssnTemplate from "../Holdings/IssnTemplate"
import { AjaxApiFetch } from "../../Utils/ajaxApiFetch"

// import Spain from "../Map/Spain";
import { Home, Menu } from "./Components"

function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  }
}
// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  })
}
const bounceTransition = {
  atEnter: {
    opacity: 0,
  },
  atLeave: {
    opacity: bounce(0),
  },
  atActive: {
    opacity: bounce(1),
  },
}

class ControlledTabs extends Component {
  constructor(props) {
    super(props)
    const { data } = this.props
    const { user } = data
    this.state = {
      combined: data,
      user,
    }
  }

  componentDidMount() {
    const { data } = this.props
    this.setState({
      data,
    })

    AjaxApiFetch({
      path: "holdings",
      group: `?group=${data.group}`,
    }).then((holdings) => {
      if (holdings.error) return
      this.setState({
        holdings,
      })
    })
  }

  render() {
    const { history, data } = this.props
    const { combined, holdings } = this.state
    const { user, library } = combined
    const isLive = library.branchvalid === "Y"
    const libraryBranchcode = user.userid
    const isAdmin = user ? user.categorycode === GENERIC.ADMIN.TYPE : false
    const { group } = data
    return (
      <Row>
        <Menu {...combined} />

        <Col className="wrapper" xs={{ span: 10 }}>
          <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"
          >
            <Route exact path="/home">
              <Home {...combined} holdings={holdings} history={history} />
            </Route>

            <Route path="/home/edit-self">
              <h2>Editar Biblioteca</h2>

              <Library
                history={history}
                branchcode={libraryBranchcode}
                user={user}
                form={combined}
                add={false}
                live={isLive}
                edit
              />
            </Route>

            <Route
              exact
              path="/home/holdings/self/:tab"
              render={(routeProps) => (
                <HoldingsTemplate {...routeProps} {...combined} />
              )}
            />

            <Route
              exact
              path="/home/groups/parent/:tab/:id"
              render={(routeProps) => (
                <HoldingsTemplateParent {...routeProps} {...combined} />
              )}
            />

            <Route
              exact
              path="/home/groups/parent/:tab/:id/:hold/:type/:live"
              render={(routeProps) => (
                <HoldingsTemplateParent {...routeProps} {...combined} />
              )}
            />

            <Route
              path="/home/holdings/self/:tab/:id/:hold/:type/:live"
              render={(routeProps) => (
                <HoldingsTemplate {...routeProps} {...combined} />
              )}
            />

            <Route path="/home/add">
              <h2>Solicitar Alta Bibliioteca</h2>
              <Library
                history={history}
                branchcode={libraryBranchcode}
                path="add"
                user={user}
              />
            </Route>

            <Route exact path="/home/groups">
              <h2>Gestión de Red</h2>
              <DataGrid
                history={history}
                user={user}
                path="library-relationships"
                group={combined.group}
              />
            </Route>

            <Route exact path="/home/groups/:library">
              <h2>Gestión de Red Biblioteca</h2>
              <Library pull history={history} {...combined} />
            </Route>

            <Route exact path="/home/request-holdings">
              <FormRequest user={user} library={group} history={history} />
            </Route>

            <Route exact path="/home/libraries">
              <h2>Lista de Bibliotecas</h2>
              <DataGrid history={history} path="library" live data={combined} />
            </Route>

            <Route path="/home/libraries/:library">
              <h2>Editar Biblioteca</h2>
              <Library
                edit
                path="live-library"
                history={history}
                pull
                branchcode={combined.group}
                {...combined}
              />
            </Route>

            <Route path="/home/requests">
              <h2>Gestión de Solicitudes</h2>
              <DataGrid
                history={history}
                path="request-data"
                group={combined.group}
              />
            </Route>

            {isAdmin ? (
              <Route exact path="/home/valid">
                <h2>Alta de Bibliotecas Pendientes</h2>
                <DataGrid
                  history={history}
                  path="temporary-records"
                  data={combined}
                />
              </Route>
            ) : (
              <Redirect to="/login" />
            )}

            <Route exact path="/home/valid/:library">
              <h2>Validar Biblioteca</h2>
              <Library
                edit
                path="temporary-records"
                history={history}
                pull
                {...combined}
              />
            </Route>

            {isAdmin ? (
              <Route path="/home/perms">
                <DataGridGroups path="groups" />
              </Route>
            ) : (
              <Redirect to="/login" />
            )}

            {isAdmin ? (
              <Route path="/home/blacklist">
                <BlackListTemplate history={history} path="blacklist" />
              </Route>
            ) : (
              <Redirect to="/login" />
            )}

            {isAdmin ? (
              <Route path="/home/manage-issn">
                <IssnTemplate history={history} />
              </Route>
            ) : (
              <Redirect to="/login" />
            )}

            <Route exact path="*">
              <Home props={combined} />
            </Route>
          </AnimatedSwitch>
        </Col>
      </Row>
    )
  }
}
export default withRouter(ControlledTabs)
