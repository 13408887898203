/* eslint-disable camelcase */
import React, { Component } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { Messages } from "primereact/messages"
import { Checkbox } from "primereact/checkbox"
import { ProgressSpinner } from "primereact/progressspinner"

import { Switch, Route } from "react-router-dom"
import moment from "moment"
import { removeDiacritics } from "../Utils/removeDiacritics"
import * as REQUEST from "../constants/request"
import { LIBRARY } from "../constants/library"
import * as GENERIC from "../constants/generic"

import { AjaxApiFetch } from "../Utils/ajaxApiFetch"

import { sortAlpha } from "../Utils/sort"

import { locale } from "../constants/language.config"

const country = sortAlpha(GENERIC.COUNTRY)

const branch_type = [
  {
    value: "ALL",
    label: "Todas",
  },
  {
    value: "Organismo Público de Investigación",
    label: "Organismo Público de Investigación",
  },
  { value: "Universidad Pública", label: "Universidad Pública" },
  {
    value: "Hospital del Sistema Público",
    label: "Hospital del Sistema Público",
  },
  {
    value: "Biblioteca Virtual de Salud",
    label: "Biblioteca Virtual de Salud",
  },
  { value: "Consejería de Salud", label: "Consejería de Salud" },
  { value: "Hospital Privado", label: "Hospital Privado" },
  {
    value: "Colegios, Asociación profesional y Academia",
    label: "Colegios, Asociación profesional y Academia",
  },
  { value: "Otra", label: "Otra" },
  { value: "Otros Ministerios", label: "Otros Ministerios" },
  { value: "Fundación", label: "Fundación" },
  { value: "Otras Consejerías", label: "Otras Consejerías" },
  {
    value: "Colegios, Asociaciones profesionales y Academias",
    label: "Colegios, Asociaciones profesionales y Academias",
  },
  { value: "Universidad Privada", label: "Universidad Privada" },
  {
    value: "Mº de Sanidad u organismo dependiente",
    label: "Mº de Sanidad u organismo dependiente",
  },
]

const getHoldingsType = (id) => locale[id] || id

export class DataGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      path: props.path,
      type: props.type,
      group: "",
      rows: [],
      columns: [],
      globalFilter: "",
      edit: false,
      holdings: false,
      publish: false,
      holding: {},
      data: {},
      visible: false,
      message: { header: "", message: "" },
      valid: false,
      library_type: "",
      filters: { branchstate: "", branchcountry: "" },
      loading: false,
    }

    this.buildTable = this.buildTable.bind(this)
    this.popUpTemplate = this.popUpTemplate.bind(this)
    this.getLibraryEdit = this.getLibraryEdit.bind(this)
    this.linkTemplate = this.linkTemplate.bind(this)
    this.buildTemplates = this.buildTemplates.bind(this)
    this.backToTable = this.backToTable.bind(this)
    this.getHoldingsEdit = this.getHoldingsEdit.bind(this)
    this.holdingsTemplate = this.holdingsTemplate.bind(this)
    this.holdingsEditLog = this.holdingsEditLog.bind(this)
    this.holdingsEditTemplate = this.holdingsEditTemplate.bind(this)
    this.deleteTemplate = this.deleteTemplate.bind(this)
    this.deleteBlackListItem = this.deleteBlackListItem.bind(this)
    this.loadHoldingsTemplate = this.loadHoldingsTemplate.bind(this)
    this.loadHoldingsDeleteTemplate = this.loadHoldingsDeleteTemplate.bind(this)
    this.deleteHoldings = this.deleteHoldings.bind(this)
    this.export = this.export.bind(this)
    this.update = this.update.bind(this)
    this.selectRequestTemplate = this.selectRequestTemplate.bind(this)
    this.checkboxTemplate = this.checkboxTemplate.bind(this)
    this.setCoutryChange = this.setCoutryChange.bind(this)
    this.setTypeChange = this.setTypeChange.bind(this)
    this.setLibraryList = this.setLibraryList.bind(this)
  }

  update() {
    let { path, group, data } = this.props
    path = path === "library-viewer" ? "library" : path
    group = group ? `?group=${group}` : ""
    const user = JSON.parse(localStorage.getItem("user"))
    const edit = this.props.path !== "library-viewer"

    const config = {
      path,
      group,
    }
    this.setState({
      edit,
      path,
      group,
      loading: true,
      parent:
        user && user.group_id && user.group_id.length > 0
          ? user.group_id[0].nombre_padre
          : "",
    })

    AjaxApiFetch(config).then((_data) => {
      this.setState({
        loading: false,
      })

      if (_data.error) return
      let fetchData
      if (path === "library") {
        fetchData = _data.libraryList || []
        if (_data.user && !data) {
          localStorage.setItem("user", JSON.stringify(_data.user))
        }
      } else {
        fetchData = _data
      }
      if (config.path === "request-data") {
        fetchData = _data.map((i) => {
          return i
        })
      }
      this.buildTable(fetchData)
    })
  }

  componentDidMount() {
    this.update()
  }

  componentDidUpdate(next, prev) {
    if (next.update || next.refresh) {
      this.update()
    }
  }

  buildTable(data) {
    if (!data || data.error) return
    const { type, path } = this.state
    let header = []
    let result = []
    const fields = LIBRARY.FIELDS.library

    if (path === "holdings") {
      if (type === "holdings-loaded") {
        header = data.loaded.headings
        result = [...data.loaded.rows]
      } else if (type === "holdings-pending") {
        header = data.pending.headings
        result = [...data.pending.rows]
      }
    } else if (path === "library-relationships") {
      result =
        data.length &&
        data.map((i) => {
          i["holdings-edit"] = "holdings-edit"
          return i
        })
      header = Object.keys(result[0]).map((i) => ({ field: i, header: i }))
    } else if (path === "temporary-records") {
      header =
        data.length > 0
          ? Object.keys(data[0]).map((i) => ({
              field: i,
              header: fields[i] ? fields[i].label : i,
            }))
          : []
      result = data.map((i) => {
        moment.locale("es")
        const approved = i.approvedTimeStamp
        const created = i.createdTimeStamp
        i.createdTimeStamp = created && moment(created).format("lll")
        i.approvedTimeStamp = approved && moment(approved).format("lll")
        return i
      })
    } else {
      header =
        data.length > 0
          ? Object.keys(data[0]).map((i) => ({
              field: i,
              header: fields[i] ? fields[i].label : i,
            }))
          : []
      result = data
    }

    this.setState({
      rows: result,
      columns: header,
    })
  }

  backToTable(data) {
    const { holdings } = this.state
    this.setState({
      edit: false,
      holdings: false,
      publish: false,
    })

    !holdings && this.update()
  }

  buildLibraryEdit(data) {
    const user = this.props.data && this.props.data.user
    const { path } = this.props
    data.user = user
    this.setState({
      data,
      edit: true,
      user,
      path,
    })
  }

  getLibraryEdit(event) {
    const id = event.target.parentElement.id || event.target.id
    const { data, group, path } = this.props
    const self = id === data && data.branchcode
    const parent = data && data.parent ? data.parent.id_grupo_padre : false
    /*
    CAN EDIT
    */
    const is_admin = data && data.user.categorycode === GENERIC.ADMIN.TYPE
    const edit = Boolean(is_admin || parent || self || group)

    let route

    switch (path) {
      case "temporary-records":
        route = "valid"
        break
      case "library-relationships":
        route = "groups"
        break
      case "library-viewer":
        route = "library-viewer"
        break
      default:
        route = "libraries"
    }

    const value = `${(route !== "library-viewer" ? "/home/" : "/") + route}/${id}${
      edit ? "&edit=true" : ""
    }${parent ? `&parent=${parent}` : ""}${group ? `&group=${group}` : ""}${
      path ? `&path=${path}` : ""
    }`

    this.props.history.push(value)
  }

  linkTemplate(rowData, column) {
    if (rowData.branchcode) {
      return (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`/library-viewer/${rowData.branchcode}&path=library-viewer`}
        >
          {rowData.branchname}
        </a>
      )
    } else {
      return rowData.branchname
    }
  }

  defaultemplate(rowData, column) {
    return rowData
  }

  getHoldingsEdit(event) {
    const target = event ? event.target : {}
    const id = event ? target.id || target.parentElement.id : {}
    const { type } =
      this.props || event.target.attributes.getNamedItem("data-operation")
    const attribute = event
      ? target.attributes.getNamedItem("data-operation") ||
        target.parentElement.attributes.getNamedItem("data-operation") ||
        target.attributes.getNamedItem("data-operation")
      : {}
    const operation = event && attribute ? attribute.value : ""
    const { group } = this.props

    this.setState({
      publish: true,
      operation,
    })
    if (operation === "holdings-parent") {
      AjaxApiFetch({
        path: "holdings",
        group: `/?library=${id}&${operation}`,
      }).then((data) => {
        this.setState({
          path: "holdings-parent",
          holding: data,
          group: id,
        })
      })
      this.props.history.push(`/home/groups/parent/0/${id}`)
    } else {
      AjaxApiFetch({
        path: "holdings-stage",
        group: `/?library=${group}&${operation}`,
      }).then((data) => {
        if (data.error) return
        const table = data.map((i) => {
          i["delete-holdings"] = "delete-holdings-row"
          if (type === "holdings-pending" && operation === "yes") {
            i["select-holdings"] = false
          }
          return i
        })
        this.buildTable(table)
      })
      this.setState({
        path: "holdings-stage",
      })
    }
  }

  deleteBlackListItem(event) {
    const id = event.target.parentElement.id || event.target.id
    const title =
      event.target.parentElement.attributes.getNamedItem("data-title") ||
      event.target.attributes.getNamedItem("data-title")

    const { rows } = this.state
    this.setState({
      visible: true,
      message: {
        header: `${locale["Delete from blacklist"]}`,
        message: `${locale.Delete} "${title.value}" ${locale["Delete step"]}`,
        onclick: () => {
          AjaxApiFetch({
            path: "blacklist-delete",
            group: `/?id=${id}`,
          }).then((data) => {
            const deleted = rows.filter((i) => i.id !== parseInt(id))
            this.setState({
              rows: deleted,
              visible: false,
            })
          })
        },
      },
    })
  }

  deleteTemplate(rowData, column) {
    return (
      <Button
        icon="pi pi-trash"
        label="Borrar"
        className="p-button-rounded remove"
        data-title={rowData.titulo}
        id={rowData.id}
        onClick={this.deleteBlackListItem}
      />
    )
  }

  holdingsTemplate(rowData, column) {
    return (
      <div
        className="click-template"
        onClick={this.getHoldingsEdit}
        data-operation={rowData.operation}
        id={rowData["Tipo de Fondo"]}
      >
        {rowData["Tipo de Fondo"]}
      </div>
    )
  }

  holdingsEditLog(event) {
    const { group, type, parent } = this.props
    const target = event.target.nodeName === "SPAN"
    const id = event.target.parentElement.id || event.target.id
    const live = type === "holdings-loaded"
    const self = group === parent

    const barcode = target
      ? event.target.parentElement.attributes.getNamedItem("data-operation") &&
        event.target.parentElement.attributes.getNamedItem("data-operation").value
      : event.target.attributes.getNamedItem("data-operation") &&
        event.target.attributes.getNamedItem("data-operation").value
    const sort = target
      ? event.target.parentElement.attributes.getNamedItem("data-type") &&
        event.target.parentElement.attributes.getNamedItem("data-type").value
      : event.target.attributes.getNamedItem("data-type") &&
        event.target.attributes.getNamedItem("data-type").value

    const query = id || barcode
    this.props.history.push(
      `/home/${!self ? "groups/parent" : "holdings/self"}/${
        type === "holdings-loaded" ? (self ? 3 : 2) : self ? 2 : 1
      }/${group}/${query}/${sort}/${live}`
    )
  }

  holdingsEditTemplate(rowData, column) {
    const type = !rowData.barcode ? "issn" : "barcode"
    const field = column.field

    return (
      <Button
        label={`${
          field !== "biblionumber"
            ? rowData.issn || rowData.biblionumber || rowData.id_inc
            : rowData.biblionumber
        }`}
        className="issn p-button-rounded click-template holdings-list"
        data-operation={`${
          field === "biblionumber" || field === "issn"
            ? rowData.barcode || rowData.issn || rowData.id_inc
            : rowData.biblionumber
        }`}
        data-type={type}
        onClick={this.holdingsEditLog}
        id={rowData.id_inc}
      />
    )
  }

  requestISSNTemplate(rowData, column) {
    const type = !rowData.barcode ? "issn" : "barcode"
    return (
      <Button
        label={`${rowData.issn || rowData.biblionumber || rowData.id_inc}`}
        className="issn p-button-rounded click-template holdings-list"
        data-operation={`${rowData.issn || rowData.barcode || rowData.id_inc}`}
        data-type={type}
        id={rowData.id_inc}
      />
    )
  }

  deleteHoldingsAction(table, id, type) {
    const { rows } = this.state
    const { group, isParent } = this.props
    AjaxApiFetch({
      path: table,
      method: "POST",
      group: `?group=${group}&id=${id}&type=${type}&parent=${isParent}`,
    }).then((data) => {
      let deleted
      if (type === "holdings-loaded") {
        if (id.includes(group)) deleted = rows.filter((i) => i.barcode !== id)
        else {
          deleted = rows.map((i) => {
            if (i.operation === id) i.Valor = 0
            return i
          })
        }
      } else {
        const action = parseInt(id)
        if (!isNaN(action)) deleted = rows.filter((i) => i.id_inc !== parseInt(id))
        else {
          deleted = rows.map((i) => {
            if (i.operation === id) i.Valor = 0
            return i
          })
        }
      }

      this.setState({
        rows: deleted,
        visible: false,
        loading: false,
      })
    })
  }

  deleteHoldings(event) {
    const id = event.target.id || event.target.parentElement.id
    const { type } = this.state
    this.setState({
      visible: true,
      message: {
        header: `${locale.Delete}`,
        message: `${locale["Delete-Holdings-Pop-Up"]} "${getHoldingsType(id)}"?`,
        onclick: () => {
          if (type === "holdings-pending") {
            this.setState({
              loading: true,
              visible: true,
              message: null,
            })
            this.deleteHoldingsAction("stage-holdings-delete", id, type)
          } else this.deleteHoldingsAction("stage-holdings-delete", id, type)
        },
      },
    })
  }

  publishHoldings(event) {
    const id = event.target.id || event.target.parentElement.id
    const { rows, group, parent } = this.state
    const values = rows
      .filter((i) => i["select-holdings"])
      .map((i) => i.id_inc)
      .toString()
    if (values === "") return

    this.setState({
      visible: true,
      message: {
        header: `${locale["Publish holdings"]}`,
        message: `${locale["Publish holding"]} "${id}"?`,
        onclick: () => {
          this.setState({
            visible: false,
            loading: true,
          })

          AjaxApiFetch({
            path: "holdings-publish",
            method: "POST",
            group: `${group}&parent=${parent}`,
            data: {
              collection: values,
            },
          }).then((data) => {
            if (data.error) return
            const ids = values.split(",")
            this.setState({
              rows: rows.filter((i) => !ids.includes(i.id_inc.toString())),
              loading: false,
            })
          })
        },
      },
    })
  }

  loadHoldingsDeleteTemplate(rowData, column) {
    const { parent } = this.state

    if (
      (rowData.tipo_fondo || (rowData.itype && !rowData.itype.includes("EJNL-"))) &&
      parent !== this.props.group
    ) {
      return (
        <Button
          icon="pi pi-trash"
          label="Eliminar"
          id={rowData.operation || rowData.id_inc || rowData.barcode}
          className="right-float p-button-rounded remove"
          onClick={this.deleteHoldings}
        />
      )
    }

    if (
      (!rowData.operation || rowData.operation === "EJNL-") &&
      parent !== this.props.group
    ) {
      return (
        <Button
          icon="pi pi-trash"
          label="Eliminar"
          className="right-float p-button-rounded remove"
          disabled={true}
        />
      )
    }
    return (
      <Button
        icon="pi pi-trash"
        label="Eliminar"
        id={rowData.operation || rowData.id_inc || rowData.barcode}
        className="right-float p-button-rounded remove"
        onClick={this.deleteHoldings}
      />
    )
  }

  loadHoldingsTemplate(rowData, column) {
    const { edit } = this.state
    return (
      <Button
        Button
        icon={`${edit ? "pi pi-pencil" : "pi pi-eye"}`}
        label="Opciones de carga"
        className="issn p-button-rounded click-template holdings-list"
        onClick={this.getHoldingsEdit}
        data-operation="holdings-parent"
        id={rowData.Libraries || rowData.branchcode}
      />
    )
  }

  popUpTemplate(rowData, column) {
    const { edit } = this.state

    return (
      <Button
        Button
        icon={`${edit ? "pi pi-pencil" : "pi pi-eye"}`}
        label={`${rowData.Libraries || rowData.branchcode}`}
        className="issn p-button-rounded click-template"
        onClick={this.getLibraryEdit}
        id={rowData.Libraries || rowData.branchcode}
      />
    )
  }

  setRequestState(event) {
    const { rows } = this.state
    const { value } = event
    const { id } = event.target
    AjaxApiFetch({
      path: "request-update",
      group: `?id=${id}&state=${value}`,
    }).then((data) => {
      if (data) {
        this.setState({
          rows: rows.map((i) => {
            if (i.id === id) i.estado = value
            return i
          }),
        })
        this.messages.show({
          severity: "success",
          summary: "Peticiones",
          closable: false,
          detail: "Petición actualizada",
        })
      } else {
        this.messages.show({
          severity: "error",
          summary: "Peticiones",
          closable: false,
          detail: "Error al actualizar petición",
        })
      }
    })
  }

  selectAllHoldingsRows(e) {
    const { rows, selected } = this.state
    this.setState({
      selected: !selected,
      rows: rows.map((i) => {
        i["select-holdings"] = !i["select-holdings"]
        return i
      }),
    })
  }

  selectHoldingRow(e) {
    const { rows } = this.state
    const { id } = e.target
    const value = e.target.checked
    this.setState({
      rows: rows.map((i) => {
        if (i.id_inc === id) i["select-holdings"] = value
        return i
      }),
    })
  }

  selectRequestTemplate(rowData, column) {
    return (
      <Dropdown
        id={rowData.id}
        value={rowData.estado}
        options={REQUEST.REQUEST_VALUES.REQUEST_COMBO}
        onChange={(e) => this.setRequestState(e)}
        placeholder="Elegir una opcion"
      />
    )
  }

  checkboxTemplate(rowData, column) {
    return (
      <Checkbox
        id={rowData.id_inc}
        onChange={(e) => this.selectHoldingRow(e)}
        checked={rowData["select-holdings"]}
      />
    )
  }

  countryTemplate(rowData, column) {
    const name = country.filter((i) => i.value === rowData.branchcountry)
    return <div> {name[0] && name[0].label} </div>
  }

  softwareTemplate(rowData, column) {
    const softwareTypes = JSON.parse(localStorage.getItem("user")).software_types
    const value = softwareTypes.filter(
      (i) => +rowData.branch_alt_softloan === +i.value
    )
    return value[0] ? value[0].label : "No"
  }

  buildTemplates(field) {
    const { path } = this.state

    switch (field.field) {
      case "branchname":
        return this.linkTemplate
      case "Libraries":
        return this.popUpTemplate
      case "branchcode":
        return this.popUpTemplate
      case "Tipo de Fondo":
        return this.holdingsTemplate
      case "issn":
        if (path !== "request-data") {
          return this.holdingsEditTemplate
        } else {
          return this.requestISSNTemplate
        }
      case "id":
        if (path !== "request-data") return this.deleteTemplate
        else return this.defaultTemplate

      case "holdings-edit":
        return this.loadHoldingsTemplate
      case "delete-holdings":
        return this.loadHoldingsDeleteTemplate
      case "holdings-count":
        return this.valueTemplate
      case "branchvalid":
        return this.valueTemplate
      case "biblionumber":
        return this.holdingsEditTemplate
      case "estado":
        return this.selectRequestTemplate
      case "select-holdings":
        return this.checkboxTemplate
      case "branchcountry":
        return this.countryTemplate
      case "branch_alt_softloan":
        return this.softwareTemplate
      default:
    }
  }

  valueTemplate(rowData, column) {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <span className="round-number">
          {rowData.Valor || rowData.branchvalid || "N"}
        </span>
      </div>
    )
  }

  defaultTemplate(rowData, column) {
    return <div style={{ width: "100%" }}>{rowData.id}</div>
  }

  export() {
    this.dt.exportCSV()
  }

  setCoutryChange(ev) {
    this.setState({ branchcountry: ev.value })
    if (ev.value === "ALL") this.dt.filter(null, "branchcountry", "equals")
    else this.dt.filter(ev.value, "branchcountry", "equals")
  }

  setLibraryList(ev) {
    this.setState({ library_type: ev.value })
    this.dt.filter(ev.value, "branchvalid", "equals")
  }

  setTypeChange(ev) {
    this.setState({ branch_type: ev.value })
    if (ev.value === "ALL") this.dt.filter(null, "branch_alt_type", "equals")
    else this.dt.filter(ev.value, "branch_alt_type", "equals")
  }

  render() {
    const { edit, publish } = this.state
    const countrySelectFilter = (
      <Dropdown
        style={{ width: "100%" }}
        className="ui-column-filter"
        value={this.state.filters.branchcountry}
        options={country}
        onChange={this.setCoutryChange}
      />
    )

    const { holdings, message, columns, operation, selected } = this.state
    const headerTranslations = {
      biblionumber: "Biblio",
      title: "Titulo",
      issn: "ISSN",
      barcode: "Barcode",
      enumchron: "Fondo",
      itype: "Tipo de Fondo",
      itemnotes: "Editorial",
      "delete-holdings": "Eliminar",
      Paquete_e: "Paquete electrónico",
      year: "Año",
      fasciculo: "FASCÍCULO",
    }

    const dynamicColumns = columns
      .filter(
        (col) =>
          col.field !== "barcode" &&
          col.field !== "branchurl" &&
          col.field !== "pageInicio" &&
          col.field !== "pageFin" &&
          col.field !== "id_inc_padre" &&
          col.field !== "id_inc" &&
          col.field !== "solicit" &&
          col.field !== "enuchron" &&
          col.field !== "solicit" &&
          col.field !== "uuidv4" &&
          col.field !== "location" &&
          col.field !== "publishercode" &&
          col.field !== "booksellerid"
      )
      .map((col, i) => {
        if (col.field !== "branchstate") {
          if (col.field !== "id") {
            return (
              <Column
                key={col.field}
                field={col.field}
                header={headerTranslations[col.header] || col.header}
                sortable
                filterMatchMode="custom"
                filterFunction={filterFunction}
                body={this.buildTemplates(col)}
              />
            )
          }
          return false
        }
        return (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            filterMatchMode="contains"
            sortable
            filterElement={countrySelectFilter}
            body={this.buildTemplates(col)}
          />
        )
      })

    const header = (
      <div>
        <div className="filters-wrapper--wide">
          <div className="filter-wrapper">
            <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }} />

            <label>Comunidad Autónoma</label>
            <Dropdown
              className="ui-column-filter"
              placeholder="Seleccione"
              value={this.state.branchcountry}
              options={[...[{ label: "Todas", value: "" }], ...country]}
              onChange={this.setCoutryChange}
            />
          </div>

          <div className="filter-wrapper">
            <label>Nombre de la biblioteca</label>
            <InputText
              type="search"
              onInput={(e) => {
                this.dt.filter(e.target.value, "branchname", "custom")
              }}
              placeholder="Búsqueda"
              size="50"
            />
          </div>

          <div className="filter-wrapper">
            <label>Código de la biblioteca</label>
            <InputText
              type="search"
              onInput={(e) => {
                this.dt.filter(e.target.value, "branchcode", "custom")
              }}
              placeholder="Búsqueda"
              size="50"
            />
          </div>

          <div className="filter-wrapper">
            <label>Tipo de Biblioteca</label>
            <Dropdown
              className="ui-column-filter"
              placeholder="Seleccione"
              value={this.state.branch_type}
              options={branch_type}
              onChange={this.setTypeChange}
            />
          </div>
        </div>
        <div style={{ textAlign: "right", float: "right" }}>
          <Button
            type="button"
            icon="pi pi-external-link"
            iconPos="left"
            className="p-button-rounded"
            label="Exportar"
            onClick={this.export}
          />
        </div>
      </div>
    )

    const validHeader = (
      <div className="filters-wrapper--wide">
        <div className="filter-wrapper"></div>
      </div>
    )
    const simpleHeader = (
      <div className="header-wrapper">
        <div className="filters-wrapper">
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }} />
          <InputText
            type="search"
            onInput={(e) => {
              this.setState({ globalFilter: e.target.value })
            }}
            placeholder="Búsqueda"
            size="50"
          />
        </div>

        {edit && publish && (
          <Button
            icon="pi pi-arrow-left"
            label="Volver"
            className="right-float p-button-rounded"
            onClick={this.backToTable}
          />
        )}

        <div style={{ textAlign: "right", float: "right" }}>
          <Button
            type="button"
            icon="pi pi-external-link"
            iconPos="left"
            className="p-button-rounded"
            label="Exportar"
            onClick={this.export}
          />
        </div>
      </div>
    )

    function filterFunction(value, filter) {
      const v = removeDiacritics(value.toLowerCase())
      const f = removeDiacritics(filter.toLowerCase())
      if (v.includes(f)) return true
      return false
    }

    return (
      <div>
        {operation === "yes" && (
          <div>
            <Button
              icon="pi pi-check"
              label={!selected ? "Seleccionar Todos" : "Remover Todos"}
              className="p-button-rounded"
              onClick={(e) => this.selectAllHoldingsRows(e)}
            />
            <span className="spacer" />
            <Button
              icon="pi pi-check"
              label="Publicar"
              className="p-button-rounded approve"
              onClick={(e) => this.publishHoldings(e)}
            />

            <hr />
          </div>
        )}
        {holdings && (
          <Button
            icon="pi pi-arrow-left"
            label="Volver Table"
            className="right-float p-button-rounded"
            onClick={this.backToTable}
          />
        )}
        {this.state.loading && <ProgressSpinner />}
        <Switch>
          <Route path="/home/valid">
            <span>
              <DataTable
                responsive
                ref={(el) => (this.dt = el)}
                value={this.state.rows}
                paginator
                header={validHeader}
                emptyMessage={`${locale["No records found"]}`}
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                resizableColumns
                exportFilename
              >
                {dynamicColumns}
              </DataTable>
            </span>
          </Route>

          <Route path="/library-viewer">
            <DataTable
              responsive
              ref={(el) => (this.dt = el)}
              value={this.state.rows}
              paginator
              header={header}
              globalFilter={this.state.globalFilter}
              emptyMessage={`${locale["No records found"]}`}
              rows={50}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              resizableColumns
              exportFilename
            >
              {dynamicColumns}
            </DataTable>
          </Route>
          <Route exact path="/home/holdings/self/:type">
            <DataTable
              responsive
              ref={(el) => (this.dt = el)}
              value={this.state.rows}
              paginator
              header={simpleHeader}
              globalFilter={this.state.globalFilter}
              emptyMessage={`${locale["No records found"]}`}
              rows={50}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              resizableColumns
              exportFilename
            >
              {dynamicColumns}
            </DataTable>
          </Route>

          <Route path="/home/libraries">
            <span>
              <DataTable
                responsive
                ref={(el) => (this.dt = el)}
                value={this.state.rows}
                paginator
                header={header}
                globalFilter={this.state.globalFilter}
                emptyMessage={`${locale["No records found"]}`}
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                resizableColumns
                exportFilename
              >
                {dynamicColumns}
              </DataTable>
            </span>
          </Route>

          <Route path="/home/requests">
            <span>
              <DataTable
                responsive
                ref={(el) => (this.dt = el)}
                value={this.state.rows}
                paginator
                header={simpleHeader}
                globalFilter={this.state.globalFilter}
                emptyMessage={`${locale["No records found"]}`}
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                resizableColumns
                exportFilename
              >
                {dynamicColumns}
              </DataTable>
            </span>
          </Route>

          <Route path="/home/blacklist">
            <span>
              <DataTable
                responsive
                ref={(el) => (this.dt = el)}
                value={this.state.rows}
                paginator
                header={simpleHeader}
                globalFilter={this.state.globalFilter}
                emptyMessage={`${locale["No records found"]}`}
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                resizableColumns
                exportFilename
              >
                {dynamicColumns}
              </DataTable>
            </span>
          </Route>

          <Route path="/home/groups">
            <span>
              {!holdings && (
                <DataTable
                  responsive
                  ref={(el) => (this.dt = el)}
                  value={this.state.rows}
                  paginator
                  header={simpleHeader}
                  globalFilter={this.state.globalFilter}
                  emptyMessage={`${locale["No records found"]}`}
                  rows={50}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  resizableColumns
                  exportFilename
                >
                  {dynamicColumns}
                </DataTable>
              )}
            </span>
          </Route>
          <Route exact path="/home/holdings/self/:tab">
            <span>
              {!holdings && (
                <DataTable
                  responsive
                  ref={(el) => (this.dt = el)}
                  value={this.state.rows}
                  paginator
                  header={simpleHeader}
                  globalFilter={this.state.globalFilter}
                  emptyMessage={`${locale["No records found"]}`}
                  rows={50}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  resizableColumns
                  exportFilename
                >
                  {dynamicColumns}
                </DataTable>
              )}
            </span>
          </Route>
          <Route path="/home">
            <span>
              {!holdings && (
                <DataTable
                  responsive
                  ref={(el) => (this.dt = el)}
                  value={this.state.rows}
                  paginator
                  header={simpleHeader}
                  globalFilter={this.state.globalFilter}
                  emptyMessage={`${locale["No records found"]}`}
                  rows={50}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  resizableColumns
                  exportFilename
                >
                  {dynamicColumns}
                </DataTable>
              )}
            </span>
          </Route>
        </Switch>

        {message && (
          <Dialog
            header={message.header}
            visible={this.state.visible}
            style={{ width: "50vw" }}
            modal
            onHide={() => this.setState({ visible: false })}
          >
            <p>{message.message}</p>

            <Button
              icon="pi pi-trash"
              label="Cancelar"
              className="p-button-rounded cancel"
              onClick={() => this.setState({ visible: false })}
            />

            <Button
              icon="pi pi-trash"
              label="Confirmar"
              className="p-button-rounded remove"
              onClick={message.onclick}
            />
          </Dialog>
        )}

        <Messages ref={(el) => (this.messages = el)} />
      </div>
    )
  }
}
