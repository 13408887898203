import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FormRequest } from "../components/Requests/FormRequest"
import { Header } from "./shared/Header"

export class PublicRequestHoldingsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { history } = this.props
    return (
      <div className="public">
        <Header />
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={10} className="tab-content">
              <FormRequest history={history} />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
