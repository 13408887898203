export const locale = {
  yes: "Fondos de medicina listos para su publicación en el OPAC",
  pending: "Fondos de revistas pendientes de revisión y de publicación en el OPAC",
  no: "Fondos de revistas rechazadas por el administrador de CNCS",
  "Failed to upload": "No se pudieron cargar los fondos",
  "Failed to upload Logo": "No se puede cargar el Logo",
  "Failed to upload Logo Code":
    "No se puede cargar el Logo sin crear código de biblioteca",
  "library Save Error":
    "Complete todos los campos obligatorios antes de intentar enviar este formulario.",
  "Empty Request":
    "Pedido vacio, puede buscar por Título de la publicación y Issn-Isbn",
  "Upload successful": "Fondos cargados correctamente",
  "Upload Logo successful": "Logo cargado correctamente",
  "issn already exists": "El issn ya existe",
  "Select file": "Seleccionar Fichero",
  "Fill all fields": "Asegúrese de que ha rellenados todos los campos",
  "Blacklist data inserted": "Datos de la Blacklist insertados",
  "Failed for center":
    "No se ha podido cargar el fondo en el centro, compruebe que los datos son correctos.",
  "Success holding loaded":
    "El fondo se ha cargado con éxito. Podrá ver el resultado de la carga en su cuenta.",
  "Library removed": "Biblioteca eliminada",
  "Logo deleted": "Logotipo eliminado",
  "Already approved": "Ya existe biblioteca con el mismo código",
  Approved: "La biblioteca está aprobada y publicada",
  "Code Changed": "Código de biblioteca cambiado",
  "Libray Data Inserted": "Datos de biblioteca insertados",
  "Insert Users":
    'Por favor, Debe añadir personal de contacto en la pestaña "Contactos"',
  "Fill all fields2": "Por favor, complete los datos de la biblioteca",
  "User Deleted": "Usuario eliminado",
  "Users Updated / Inserted": "Usuarios actualizados / insertados",
  "Fill users form": "Por favor complete el formulario de usuarios",
  "Valid library code": "Código de biblioteca válido",
  "Existing library code": "Código de biblioteca inválido / existente",
  "Valid library": "Biblioteca válida",
  "Delete from blacklist": "Eliminar de BlackList",
  Delete: "Eliminar",
  "Delete-Holdings-Pop-Up": "Confirma que desea borrar los fondos",
  "Delete step": "de la Blacklist",
  "Delete holdings": "Eliminar fondos",
  "Delete holding": "Eliminar Fondo",
  "Publish holdings": "Publicar fondos",
  "Publish holding": "Publicar Fondo",
  "Uploading files": "Subiendo ficheros",
  "Processing files": "Procesando fichero",
  "No records found": "No se encontraron registros",
  "No holdings found in centre":
    "No se ha podido seleccionar el fondo en el centro elegido.",
  "No holdings found in centre description":
    "El centro seleccionado no ofrece servicio de obtención de documentos, por favor escoja otro centro del listado",
  "filter results": "Filtrar Resultados",
  "software message":
    "dispone de un formulario de petición de documentos, es posible que necesite tener una cuenta de SOD activa con ellos",
}
