import React, { Component } from "react"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import sw from "stopword"

import { LIBRARY } from "../../constants/library"
import { AjaxApiFetch } from "../../Utils/ajaxApiFetch"
import { locale } from "../../constants/language.config"

function filterStrings(string) {
  const es = sw.removeStopwords(string, sw.es)
  const pt = sw.removeStopwords(es, sw.pt)
  return sw.removeStopwords(pt)
}

class IssnTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      group: null,
      data: null,
      columns: [],
      rows: [],
    }

    this.holdingsEditTemplate = this.holdingsEditTemplate.bind(this)
    this.holdingsEditLog = this.holdingsEditLog.bind(this)
  }

  setSearchValue(e) {
    this.setState({
      group: e.target.value,
    })
  }

  getData() {
    const { group } = this.state
    const fields = LIBRARY.FIELDS.library
    AjaxApiFetch({
      path: "manage-issn-data",
      group: `/?group=${group}`,
    }).then((data) => {
      this.setState({
        rows: data,
        columns:
          data.length > 0
            ? Object.keys(data[0])
                .map((i) => ({
                  field: i,
                  header: fields[i] ? fields[i].label : i,
                }))
                .concat([{ field: "search", header: "Search" }])
            : [],
      })
    })
  }

  buildTemplates(field) {
    switch (field.field) {
      case "issn":
        return this.holdingsEditTemplate
      case "search":
        return this.loadSearchTemplate
      default:
    }
  }

  holdingsEditLog(event) {
    const { group, type, parent } = this.props
    const target = event.target.nodeName === "SPAN"
    const id = event.target.parentElement.id || event.target.id
    const live = type === "holdings-loaded"
    const self = group === parent

    const barcode = target
      ? event.target.parentElement.attributes.getNamedItem("data-operation") &&
        event.target.parentElement.attributes.getNamedItem("data-operation").value
      : event.target.attributes.getNamedItem("data-operation") &&
        event.target.attributes.getNamedItem("data-operation").value
    const sort = target
      ? event.target.parentElement.attributes.getNamedItem("data-type") &&
        event.target.parentElement.attributes.getNamedItem("data-type").value
      : event.target.attributes.getNamedItem("data-type") &&
        event.target.attributes.getNamedItem("data-type").value

    const query = id || barcode

    this.props.history.push(
      `/home/${
        !self ? "groups/parent" : "holdings/self"
      }/${group}/${query}/${sort}/${live}`
    )
  }

  loadSearchTemplate(rowData, column) {
    const title = rowData.titulo
    const stripped = title.replace(/\b(\w{1,3}\s|\s\w{1,3})\b/, " ")
    const themes = filterStrings(stripped.split(" "))
    const template = themes
      .filter((i) => i !== "")
      .map((i) => (
        <span>
          <input type="checkbox" /> {i}{" "}
        </span>
      ))
    return (
      <div className="search-same-titles">
        {template}
        <Button
          icon="pi pi-search"
          label="Buscar"
          className="p-button-rounded"
          onClick={this.deleteBlackListItem}
        />
      </div>
    )
  }

  holdingsEditTemplate(rowData, column) {
    const type = !rowData.barcode ? "issn" : "barcode"
    return (
      <Button
        label={`${rowData.issn || rowData.biblionumber || rowData.id_inc}`}
        className="issn p-button-rounded click-template holdings-list"
        data-operation={`${rowData.issn || rowData.barcode || rowData.id_inc}`}
        data-type={type}
        onClick={this.holdingsEditLog}
        id={rowData.id_inc}
      />
    )
  }

  render() {
    const { group, columns } = this.state

    const header = (
      <div>
        <div style={{ textAlign: "left", float: "left" }}>
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }} />
          <InputText
            type="search"
            onInput={(e) => {
              // this.setState({ globalFilter: removeDiacritics(e.target.value) })}
              this.setState({ globalFilter: e.target.value })
            }}
            placeholder="Búsqueda"
            size="50"
          />
        </div>
        <div style={{ textAlign: "right", float: "right" }}>
          <Button
            type="button"
            icon="pi pi-external-link"
            iconPos="left"
            className="p-button-rounded"
            label="Exportar"
            onClick={this.export}
          />
        </div>
      </div>
    )
    const dynamicColumns = columns
      .filter((col) => col.field !== "grupo")
      .filter((col) => col.field !== "id_inc_padre")
      .filter((col) => col.field !== "id_inc")
      .filter((col) => col.field !== "embargo")
      .filter((col) => col.field !== "solicit")

      .map((col, i) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          filter={false}
          body={this.buildTemplates(col)}
        />
      ))

    return (
      <div className="issn-check-wrapper">
        <h2>Lista de los issn que no tienen correspondencia </h2>
        <InputText
          placeholder="Buscar por Grupo"
          value={group}
          onChange={(e) => this.setSearchValue(e)}
        />
        <Button
          icon="pi pi-search"
          label="Search"
          className="p-button-rounded search"
          onClick={(e) => this.getData(e)}
        />
        <DataTable
          responsive
          ref={(el) => (this.dt = el)}
          value={this.state.rows}
          paginator
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={`${locale["No records found"]}`}
          rows={50}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          resizableColumns
          exportFilename
        >
          {dynamicColumns}
        </DataTable>
      </div>
    )
  }
}
export default IssnTemplate
