import React, { Component } from "react"
import { TabView, TabPanel } from "primereact/tabview"
import { Switch, Route } from "react-router-dom"
import { DataGrid } from "../DataGrid"
import { Holdings } from "../Holdings"
import { HoldingsButtons } from "./HoldingsButtons"

class HoldingsTemplateParent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
    }
    this.handleOnTabChange = this.handleOnTabChange.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  handleOnTabChange(event) {
    const { match } = this.props
    const { id } = match.params
    this.setState({ activeIndex: event.index })
    this.props.history.push(`/home/groups/parent/${event.index}/${id}`)
  }

  handleUpdate() {
    this.setState({
      update: true,
    })
    setTimeout(() => {
      this.setState({
        update: false,
      })
    }, 250)
  }

  render() {
    const { match, history, parent } = this.props
    const { id, tab } = match.params
    return (
      <>
        <h2>{`Gestión de Fondos - Biblioteca ${id}`}</h2>
        <TabView activeIndex={+tab} onTabChange={(e) => this.handleOnTabChange(e)}>
          {/* Can i dedup */}

          <TabPanel header="Añadir Fondo Suelto" leftIcon="pi pi-plus">
            <h3>Añadir fondo suelto</h3>
            <Holdings
              edit={false}
              insert
              group={id}
              parent={parent}
              history={history}
            />
          </TabPanel>
          <TabPanel
            id="pending"
            leftIcon="pi pi-list"
            header="Pendientes de Revisión"
          >
            <h2>
              Fondos de revistas pendientes de revisión y de publicación en el OPAC
            </h2>
            <Switch>
              <Route exact path="/home/groups/parent/:tab/:id">
                <DataGrid
                  type="holdings-pending"
                  path="holdings"
                  group={id}
                  parent={parent}
                  isParent={false}
                  history={history}
                  update={this.state.update}
                />
                <HoldingsButtons
                  handleUpdate={this.handleUpdate}
                  live={false}
                  group={id}
                />
              </Route>

              <Route
                path="/home/groups/parent/:tab/:id/:hold/:type/:live"
                render={(routeProps) => (
                  <Holdings
                    history={history}
                    {...routeProps}
                    parent
                    isParent={false}
                    edit
                    insert={false}
                  />
                )}
              />
            </Switch>
          </TabPanel>
          <TabPanel id="published" leftIcon="pi pi-eye" header="Revistas Publicadas">
            <h2>Fondos de revistas publicadas en el OPAC</h2>
            <Switch>
              <Route exact path="/home/groups/parent/:tab/:id">
                <DataGrid
                  type="holdings-loaded"
                  path="holdings"
                  group={id}
                  parent={parent}
                  isParent={false}
                  history={history}
                  update={this.state.update}
                />
                <HoldingsButtons handleUpdate={this.handleUpdate} live group={id} />
              </Route>

              <Route
                exact
                path="/home/groups/parent/:tab/:id/:hold/:type/:live"
                render={(routeProps) => (
                  <Holdings
                    history={history}
                    {...routeProps}
                    isParent={false}
                    parent
                    edit
                    insert={false}
                  />
                )}
              />
            </Switch>
          </TabPanel>
        </TabView>
      </>
    )
  }
}
export default HoldingsTemplateParent
