import React from "react"
import Octicon from "react-octicon"
import { Link, useRouteMatch } from "react-router-dom"

export const MenuLink = ({
  label,
  to,
  activeOnlyWhenExact,
  icon,
  tooltip,
  external,
}) => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  })
  return !external ? (
    <li className={match ? "active" : ""}>
      <Link to={to.replace(":tab", 0)} data-tip={tooltip}>
        <Octicon name={icon} />
        <span>{label}</span>
      </Link>
    </li>
  ) : (
    <li>
      <a
        target="_blank"
        href={`//${process.env.REACT_APP_VUFIND_URL}`}
        rel="noreferrer"
        data-tip={tooltip}
      >
        <Octicon name={icon} />
        <span>{label}</span>
      </a>
    </li>
  )
}
