import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { DataGrid } from "../components/DataGrid"
import { Header } from "./shared/Header"

export class PublicSearchLibrary extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { history } = this.props
    return (
      <div className="public">
        <Header />
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={10}>
              <div className="tab-content">
                <DataGrid path="library-viewer" history={history} />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs={12} md={10}>
              <footer className="footer">
                Instituto de Salud Carlos III. Biblioteca Nacional de Ciencias de la
                Salud - Avda. Monforte de Lemos 5. 28029 Madrid.{" "}
                <a href="mailto:cncs@isciii.es">Contacto: cncs@isciii.es</a>
              </footer>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
