import React, { Component } from "react"
import { Button } from "primereact/button"
import validator from "validator"
import { AjaxApiFetch } from "../../Utils/ajaxApiFetch"

class CheckLibraryCode extends Component {
  constructor(props) {
    super(props)

    const { show, path, validate } = this.props
    this.state = {
      show,
      validate,
      path,
    }
  }

  valid() {
    const libraryInput = document.getElementById("branchcode")
    const { value } = libraryInput
    const { validate } = this.state
    const { library } = this.props
    const timestamp = library && library.createdTImeStamp

    if (!value) return false

    AjaxApiFetch({
      path: "check-library-code",
      edit: `?library=${value}`,
    }).then((data) => {
      const isUpper = validator.isUppercase(value)
      const hasHifen = validator.matches(value, /\w+(-\w+)+/)

      if (data.error) {
        validate(false)
        return
      }

      if (
        data.live.length <= 0 &&
        (data.temp.length <= 0 ||
          (data.temp.length <= 1 &&
            data.temp[0] &&
            data.temp[0].createdTimeStamp === timestamp))
      ) {
        validate(hasHifen && isUpper)
      } else {
        validate(false)
      }
    })
  }

  render() {
    const { show } = this.state
    const { path, message, valid } = this.props
    return show && path ? (
      <div style={{ float: "right" }}>
        <Button
          id="checkCode"
          icon="pi pi-check"
          label="Verificar"
          className="p-button-rounded p-button-success"
          onClick={(e) => this.valid(e)}
          onfocusOut={(e) => this.valid(e)}
        />
        <span className="library-wrapper-code-message">
          <small style={{ color: valid ? "green" : "red" }}>{message}</small>
        </span>
      </div>
    ) : (
      ""
    )
  }
}
export default CheckLibraryCode
