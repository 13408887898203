import validator from "validator"

// Libraries
const LIBRARY = {
  USER: [
    { id: "ID", value: "", label: "" },
    { id: "branch_alt_code", value: "", label: "" },
    { id: "tipo_resp", value: "", label: "Tipo" },
    { id: "resp", value: "", label: "Resp" },
    { id: "resp_tel", value: "", label: "Telefone" },
    { id: "resp_mail", value: "", label: "Email" },
  ],
  YES_NO: [
    { label: "Si", value: "SI" },
    { label: "No", value: "NO" },
  ],
  SOFTWARE: [
    { label: "ILL17", value: "206" },
    { label: "GTBIB", value: "205" },
  ],
  LIBRARY_SELECT: [
    { label: "Biblioteca", value: "biblioteca" },
    { label: "Prestamo", value: "prestamo" },
  ],
  select: [
    "Solo al centro de compra",
    "Al centro de compra y a todos los centros consorciados",
    "Al centro de compra y selección de centros consorciados",
  ],
  FIELDS: {
    library: {
      branchvalid: {
        type: "switch",
        required: false,
        double: true,
        label: "Biblioteca Válida",
        field: "branchvalid",
        visible: true,
      },
      approvedTimeStamp: {
        type: "input",
        required: false,
        double: true,
        label: "Fecha de alta",
        field: "createdTimeStamp",
        visible: false,
      },
      createdTimeStamp: {
        type: "input",
        required: false,
        double: true,
        label: "Fecha de solicitud",
        field: "createdTimeStamp",
        visible: false,
      },
      branchcode: {
        type: "input",
        size: 0,
        required: true,
        edit: "isciii_admin",
        double: false,
        label: "Código de la biblioteca",
        alt_label: "Propuesta de codigo de la biblioteca",
        field: "branchcode",
        method: validator.isEmpty,
        visible: true,
        valid: "branchcode",
        validWhen: false,
      },
      branchname: {
        type: "input",
        size: 255,
        required: true,
        edit: "isciii_admin",
        double: true,
        label: "Nombre de la biblioteca",
        field: "branchname",
        method: validator.isEmpty,
        validWhen: false,
        message: "Nombre de la biblioteca",
        visible: true,
      },
      branchaddress1: {
        type: "input",
        size: 255,
        required: false,
        double: true,
        label: "Dirección postal",
        field: "branchaddress1",
        message: "Dirección postal",
        visible: true,
      },
      branchzip: {
        type: "input",
        size: 255,
        required: false,
        label: "Código postal",
        field: "branchzip",
        visible: true,
        options: "ES",
      },
      branchcity: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "Ciudad",
        field: "branchcity",
        method: validator.isEmpty,
        validWhen: false,
        visible: true,
      },
      branchstate: {
        type: "input",
        size: 255,
        required: false,
        double: true,
        label: "Provincia ",
        field: "branchstate",
        visible: true,
      },
      branchcountry: {
        type: "select",
        size: 255,
        required: true,
        double: true,
        label: "Comunidad Autónoma",
        field: "branchcountry",
        validWhen: false,
        method: validator.isEmpty,
        visible: true,
      },
      branchphone: {
        type: "input",
        size: 0,
        required: true,
        double: true,
        label: "Teléfono",
        field: "branchphone",
        validWhen: false,
        method: validator.isEmpty,
        visible: true,
      },
      branchfax: {
        type: "input",
        size: 0,
        required: false,
        double: true,
        label: "Fax",
        field: "branchfax",
        validWhen: false,
        message: "Numeric",
        visible: true,
      },
      branchemail: {
        type: "input",
        size: 0,
        required: true,
        double: true,
        label: "Correo electrónico",
        field: "branchemail",
        validWhen: true,
        method: validator.isEmail.bind({ ignore_whitespace: false }),
        visible: true,
        message: "Nombre de la biblioteca",
      },
      branchreplyto: {
        type: "input",
        size: 0,
        required: true,
        double: true,
        label: "branchreplyto",
        field: "branchreplyto",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branchreturnpath: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branchreturnpath",
        field: "branchreturnpath",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branchurl: {
        type: "input",
        size: 255,
        required: false,
        double: false,
        label: "URL página web de la biblioteca",
        field: "branchurl",
        visible: true,
      },
      issuing: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "issuing",
        field: "issuing",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branchip: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branchip",
        field: "branchip",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },

      branchnotes: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branchnotes",
        field: "branchnotes",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },

      opac_info: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "opac_info",
        field: "opac_info",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branchobs: {
        type: "input",
        size: 255,
        required: false,
        double: true,
        label: "Observaciones",
        field: "branchobs",
        visible: true,
      },
      branches_alt_id: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branches_alt_id",
        field: "branches_alt_id",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_code: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branch_alt_code",
        field: "branch_alt_code",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_type: {
        type: "select",
        size: 255,
        required: true,
        double: true,
        label: "Tipo de biblioteca",
        field: "branch_alt_type",
        method: validator.isEmpty,
        validWhen: false,
        visible: true,
      },
      branch_alt_softloan: {
        type: "select",
        size: 255,
        required: false,
        double: true,
        label: "Sóftware del préstamo",
        field: "branch_alt_softloan",
        visible: true,
      },
      branch_alt_srvloan: {
        type: "select",
        size: 255,
        required: false,
        double: true,
        label: "branch_alt_srvloan",
        field: "branch_alt_srvloan",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_resp: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branch_alt_resp",
        field: "branch_alt_resp",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_resp_tel: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branch_alt_resp_tel",
        field: "branch_alt_resp_tel",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_resp_mail: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branch_alt_resp_mail",
        field: "branch_alt_resp_mail",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_resp_loan_tel: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branch_alt_resp_loan_tel",
        field: "branch_alt_resp_loan_tel",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_resp_loan_mail: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branch_alt_resp_loan_mail",
        field: "branch_alt_resp_loan_mail",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_srvlimit: {
        type: "input",
        size: 255,
        required: false,
        double: true,
        label: "Limitación servicio",
        field: "branch_alt_srvlimit",
        visible: true,
      },
      branch_alt_close_alert: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branch_alt_close_alert",
        field: "branch_alt_close_alert",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_alter_fecha: {
        type: "input",
        size: 255,
        required: false,
        double: true,
        label: "Limitación servicio",
        field: "branch_alt_alter_fecha",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_url_cat: {
        type: "input",
        size: 255,
        required: false,
        double: true,
        label: "URL página web del catálogo de la biblioteca",
        field: "branch_alt_url_cat",
        visible: true,
      },
      branch_alt_tarifa: {
        type: "input",
        size: 255,
        required: false,
        double: true,
        label: "Tarifa préstamo",
        field: "branch_alt_tarifa",
        visible: true,
      },

      branch_alt_tipo_resp: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "branch_alt_tipo_resp",
        field: "branch_alt_tipo_resp",
        method: validator.isEmpty,
        validWhen: false,
        visible: false,
      },
      branch_alt_spec: {
        type: "input",
        size: 255,
        required: false,
        double: false,
        label: "Especialidad",
        field: "branch_alt_spec",
        description: "Si desea añadir más de una especialidad, separela con una ','",
        visible: true,
      },
      branch_alt_dep: {
        type: "input",
        size: 255,
        required: false,
        double: false,
        label: "Institución de la que depende",
        field: "branch_alt_dep",
        visible: true,
      },

      centro: {
        type: "select",
        size: 255,
        required: false,
        double: true,
        label: "Centro de préstamo",
        field: "centro",
        visible: true,
      },
      branch_alt_loan_central: {
        type: "select",
        size: 255,
        required: false,
        double: false,
        label: "Servicio de préstamo centralizado",
        field: "branch_alt_loan_central",
        visible: true,
      },
      branch_alt_url_form_loan: {
        type: "input",
        size: 255,
        required: true,
        double: true,
        label: "URL del formulario del préstamo",
        field: "branch_alt_url_form_loan",
        method: validator.isURL,
        validWhen: true,
        visible: false,
      },
    },
  },
}

const DEFAULT_FORM = {
  library: {
    branchvalid: "N",
    branchname: "",
    branchcode: "",
    branch_alt_type: "",
    branch_alt_dep: "",
    branch_alt_spec: "",
    branchobs: "",

    branchphone: "",
    branchfax: "",
    branchemail: "",
    branchaddress1: "",
    branchaddress2: "",
    branchaddress3: "",
    branchzip: "",
    branchcity: "",
    branchstate: "",
    branchcountry: "",
    branchreplyto: "",
    branchreturnpath: "",
    branchurl: "",
    branch_alt_url_cat: "",

    branch_alt_nombre_soft_loan: "",

    issuing: "",
    branchip: "",
    branchnotes: "",
    opac_info: "",
    branches_alt_id: "",
    branch_alt_code: "",
    branch_alt_resp: "",
    branch_alt_resp_tel: "",
    branch_alt_resp_mail: "",
    branch_alt_resp_loan_tel: "",
    branch_alt_resp_loan_mail: "",
    centro: "",
    branch_alt_softloan: "",
    branch_alt_url_form_loan: "",
    branch_alt_srvloan: "",
    branch_alt_close_alert: "",

    branch_alt_loan_central: "",

    branch_alt_tarifa: "",
    branch_alt_alter_fecha: "",
    branch_alt_tipo_resp: "",
    branch_alt_srvlimit: "",
  },
  group: "",
  users: [
    {
      ID: 0,
      branch_alt_code: "",
      tipo_resp: "",
      resp: "",
      resp_tel: "",
      resp_mail: "",
    },
  ],
  branch_type: [
    {
      value: "Organismo Público de Investigación",
      label: "Organismo Público de Investigación",
    },
    { value: "Universidad Pública", label: "Universidad Pública" },
    {
      value: "Hospital del Sistema Público",
      label: "Hospital del Sistema Público",
    },
    {
      value: "Biblioteca Virtual de Salud",
      label: "Biblioteca Virtual de Salud",
    },
    { value: "Consejería de Salud", label: "Consejería de Salud" },
    { value: "Hospital Privado", label: "Hospital Privado" },
    {
      value: "Colegios, Asociación profesional y Academia",
      label: "Colegios, Asociación profesional y Academia",
    },
    { value: "Otra", label: "Otra" },
    { value: "Otros Ministerios", label: "Otros Ministerios" },
    { value: "Fundación", label: "Fundación" },
    { value: "Otras Consejerías", label: "Otras Consejerías" },
    {
      value: "Colegios, Asociaciones profesionales y Academias",
      label: "Colegios, Asociaciones profesionales y Academias",
    },
    { value: "Universidad Privada", label: "Universidad Privada" },
    {
      value: "Mº de Sanidad u organismo dependiente",
      label: "Mº de Sanidad u organismo dependiente",
    },
  ],
  user: {},
}

export { LIBRARY, DEFAULT_FORM }
