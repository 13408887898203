import React, { Component } from "react"
import Octicon, { Person } from "@primer/octicons-react"
import { Row, Col } from "react-bootstrap"
import { Menu } from "primereact/menu"
import { Button } from "primereact/button"
import { Redirect } from "react-router-dom"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: false,
      user: JSON.parse(localStorage.getItem("user") || '{"error":"true"}'),
    }
    this.getValues = this.getValues.bind(this)
    this.setLogoutPath = this.setLogoutPath.bind(this)
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"))
    if (user) {
      this.setState({
        login: true,
        user,
      })
    }
  }

  setVufindPath() {
    window.open(
      `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_VUFIND_URL}`
    )
  }

  setLogoutPath() {
    localStorage.setItem("user", null)
    this.setState({
      login: false,
      user: null,
    })
  }

  getValues() {
    const items = [
      {
        label: "Catálogo Online",
        icon: "pi pi-external-link",
        command: () => {
          this.setVufindPath()
        },
      },
      {
        label: "Cerrar sesion",
        icon: "pi pi-fw pi-sign-out",
        command: () => {
          this.setLogoutPath()
        },
      },
    ]
    return items
  }

  render() {
    const { user } = this.state
    const isParent = user && user.is_parent
    return (
      <div className="header-wrap">
        {user ? (
          <Row>
            <Col xs={1} />
            <Col xs={3}>
              <h2 className="header-wrap-headline">
                Catálogo Colectivo Nacional de Publicaciones de Ciencias de la Salud
              </h2>
            </Col>

            <Col xs={4}>
              <Row>
                <Col xs={12}>
                  <div style={{ marginRight: "25px", display: "inline-block" }}>
                    <Octicon icon={Person} />
                  </div>
                  {user.userid}
                  <div className="header-wrap-user-type-wrapper">
                    {user.categorycode === "S" ? (
                      <span className="header-wrap-user-type-pill admin">Admin</span>
                    ) : (
                      <span className="header-wrap-user-type-pill regular">
                        Usuario
                      </span>
                    )}
                    {isParent ? (
                      <span className="header-wrap-user-type-pill orange">
                        Grupo
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={2}>{user.branch && user.branch.branchname}</Col>

            <Col xs={1}>
              <Menu model={this.getValues()} popup ref={(el) => (this.menu = el)} />
              <Button
                label="Menu"
                icon="pi pi-ellipsis-v"
                onClick={(event) => this.menu.toggle(event)}
              />
            </Col>
          </Row>
        ) : (
          <Redirect to="/login" />
        )}
      </div>
    )
  }
}
export default Header
//
