import React, { Component } from "react"
import { connect } from "react-redux"
import moment from "moment"

import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { InputSwitch } from "primereact/inputswitch"
import { Captcha } from "primereact/captcha"
import { ProgressSpinner } from "primereact/progressspinner"
import { Dialog } from "primereact/dialog"
import { Row, Col, Tabs, Tab } from "react-bootstrap"
import { Redirect } from "react-router-dom"

import Octicon from "react-octicon"
import { Dropdown } from "primereact/dropdown"
import { Messages } from "primereact/messages"

import { LIBRARY, DEFAULT_FORM } from "../constants/library"
import * as GENERIC from "../constants/generic"
import { server } from "../config/backend"

import { AjaxApiFetch } from "../Utils/ajaxApiFetch"
import FormValidator from "../Utils/FormValidator"
import CheckLibraryCode from "./Library/CheckLibraryCode"

import { Upload } from "./Forms/Upload"
import { AlertForm } from "./Forms/AlertForm"
import { UploadLogo } from "./Library/UploadLogo"
import { sortAlpha } from "../Utils/sort"
import { locale } from "../constants/language.config"

import { getLibrary, deleteLibrary } from "../actions/library"

const country = sortAlpha(GENERIC.COUNTRY)
const defaultForm = { ...DEFAULT_FORM }

function disableBlurOnType(event) {
  event.stopImmediatePropagation()
}

function parseLocalStorage(item) {
  try {
    return JSON.parse(localStorage.getItem(item))
  } catch (error) {
    return []
  }
}

class Library extends Component {
  constructor(props) {
    super(props)
    let { form, path, edit, live } = this.props

    if (!form) form = defaultForm
    const { library } = form
    const branchcode = library ? library.branchcode : ""

    this.state = {
      code: "",
      form,
      shouldSave: false,
      users: form ? form.users : LIBRARY.USER,
      branch_type: form ? form.branch_type : {},
      message: "",
      fields: LIBRARY.FIELDS.library || {},
      user: form.user,
      path,
      logo: "",
      active: false,
      branchcode,
      branchcode_valid: null,
      users_valid: false,
      edit,
      live,
      redirect: false,
      redirectBack: false,
      processing: false,
      software: [
        { label: "Sin Software", value: 0 },
        ...parseLocalStorage("user").software_types,
      ],

      loading: false,
    }

    this.valuesForSelect = this.valuesForSelect.bind(this)
    this.deleteLogo = this.deleteLogo.bind(this)
    this.revealUploadLogo = this.revealUploadLogo.bind(this)
    this.printMessage = this.printMessage.bind(this)
    this.saveForm = this.saveForm.bind(this)
    this.validBranchCode = this.validBranchCode.bind(this)
    this.showResponse = this.showResponse.bind(this)
    this.saveAlertContent = this.saveAlertContent.bind(this)
    this.handleUserDeletion = this.handleUserDeletion.bind(this)
    this.savePublicForm = this.savePublicForm.bind(this)
    this.rejectLibrary = this.rejectLibrary.bind(this)
    this.changeLibraryCode = this.changeLibraryCode.bind(this)
  }

  componentDidMount() {
    const { entity, pull, history, getLibrary } = this.props
    const library = history && history.location.pathname.split("/").pop()

    const { fields } = this.state

    if (entity || pull) {
      const data = getLibrary(library).then((results) => {
        if (results && results.data.error) return

        const state = results.data && results.data.pop()

        const { users, country, branch_type } = state

        const isValid = !Object.keys(fields)
          .reduce((a, i) => {
            if (fields[i].required && fields[i].visible) a.push(!fields[i].isInvalid)
            return a
          }, [])
          .includes(false)

        const softUrl = fields.branch_alt_url_form_loan
        const hasSoftware =
          state.library.branch_alt_url_form_loan &&
          state.library.branch_alt_url_form_loan !== ""
        softUrl.visible = hasSoftware

        const newFields = {
          ...fields,
          softUrl,
        }

        this.setState({
          form: state,
          code: state.library.branchcode,
          users,
          country,
          branch_type,
          isValid,
          loading: false,
          fields: hasSoftware ? newFields : fields,
        })
        window.scrollTo(0, 0)

        return data
      })

      this.setState({
        loading: true,
      })
    }

    window.addEventListener("input", disableBlurOnType, true)
  }

  setUserInputValue(event) {
    this.setState({
      shouldSave: true,
    })
    const id = !event.target.id.includes("-")
      ? event.target.id
      : event.target.id.split("-")[0]
    const { value } = event.target
    const { users } = this.state
    const row = event.target.parentElement
      ? event.target.parentElement.getAttribute("data-row")
      : event.target.id.split("-")[1]
    users[row][id] = value
    this.setState({
      users,
    })
  }

  setInputValue(event) {
    const { id } = event.target
    const { value } = event.target
    const { form, fields } = this.state

    form.library[id] = value

    console.log(event)
    const hasSoftware = id === "branch_alt_softloan"
    const softUrl = fields.branch_alt_url_form_loan
    let newFields = {}

    if (hasSoftware) {
      if (value && value !== 0) {
        softUrl.visible = true
      } else {
        softUrl.visible = false
        form.library.branch_alt_url_form_loan = ""
      }
      newFields = {
        ...fields,
        softUrl,
      }
    }

    this.validateForm() ??
      this.setState({
        form,
        fields: hasSoftware ? newFields : fields,
      })
  }

  componentWillUnmount() {
    window.removeEventListener("input", disableBlurOnType, true)
  }

  revealUploadLogo() {
    const { active } = this.state
    this.setState({
      active: !active,
    })
  }

  deleteLogo() {
    const { form } = this.state
    const { library } = form
    const group = library.branchcode
    const newform = { ...form }
    newform.logo = null

    AjaxApiFetch({
      path: "delete-logo",
      edit: `?library=${group}`,
    }).then((data) => {
      this.printMessage({
        severity: "success",
        detail: `${locale["Logo deleted"]}}`,
      })
      this.setState({
        form: newform,
      })
    })
  }

  rejectLibrary() {
    const { form } = this.state
    const { library } = form
    const group = library.branchcode

    AjaxApiFetch({
      path: "temporary-library-refused",
      edit: `?library=${group}`,
    }).then((data) => {
      this.printMessage({
        severity: "success",
        detail: `${locale["Library removed"]}}`,
      })
      this.setState({
        redirectBack: "/valid",
      })
    })
  }

  deleteLibrary() {
    const { form } = this.state
    const { library } = form
    const group = library.branchcode

    this.props.deleteLibrary(group).then((results) => {
      if (results.error) return
      this.printMessage({
        severity: "success",
        detail: `${locale["Library removed"]}}`,
      })
      this.setState({
        processing: false,
      })
      this.props.history.push("/home/libraries")
    })
    this.setState({
      processing: true,
    })
  }

  approveLibrary(e) {
    const { form, fields } = this.state
    const { branchcode } = form.library
    const { library } = form

    const isValid = Object.keys(fields).reduce((a, i) => {
      if (fields[i].required && fields[i].visible) {
        if (!fields[i].isInvalid) a.push(!fields[i].isInvalid)
      }
      return a
    }, [])

    const valid = isValid.length > 0 && !isValid.includes(false)

    if (valid) {
      AjaxApiFetch({
        path: "aprove-library-data",
        edit: `?library=${branchcode}`,
      }).then((data) => {
        this.setState({
          processing: false,
        })
        if (data.error) {
          const message = locale["Already approved"]
          this.printMessage({
            severity: "error",
            detail: `${message}`,
          })
        } else {
          const message = locale.Approved
          this.printMessage({
            severity: "success",
            detail: `${message}`,
          })
          library.branchvalid = "Y"
        }
      })
      this.setState({
        processing: true,
      })
    }
  }

  changeLibraryCode(e) {
    const { code, form } = this.state
    const value = form.library.branchcode

    AjaxApiFetch({
      path: "update-library-code",
      edit: `?library=${code}&code=${value}`,
    }).then((data) => {
      this.printMessage({
        severity: "success",
        detail: `${locale["Code Changed"]}}`,
      })
      this.setState({
        processing: false,
      })
      this.props.history.push("/home/valid")
    })
    this.setState({
      processing: true,
    })
  }

  saveForm() {
    const { form, fields } = this.state
    const { edit, path, parent, user } = this.props
    const admin = user && user.categorycode === GENERIC.ADMIN.TYPE
    const { library } = form
    const isPublic = path === "public"
    const redirectPath = path === "temporary-records" || path === "library"
    const self = user && user.userid === library.branchcode

    this.validateForm()

    if (!admin && !self && !parent) {
      const validate = this.validBranchCode
      new CheckLibraryCode({ show: true, validate }).valid()
    } else {
      this.setState({ branchcode_valid: true })
    }

    const isValid = !Object.keys(fields)
      .reduce((a, i) => {
        if (fields[i].required && fields[i].visible) a.push(!fields[i].isInvalid)
        if (fields[i].required && fields[i].visible && library[i] === "") {
          a.push(false)
        }
        return a
      }, [])
      .includes(false)

    if (isValid) {
      const branches_alt_keys = Object.keys(library)
        .filter((i) => i.includes("_alt") || i.includes("centro"))
        .reduce((a = {}, i) => {
          a[i] = library[i]
          a.branch_alt_code = library.branchcode
          a.branches_alt_id = null
          return a
        }, {})

      const branches_keys = Object.keys(library)
        .filter((i) => !i.includes("_alt") && !i.includes("centro"))
        .reduce((a = {}, i) => {
          a.issuing = Math.ceil(Math.random(1) * 100)
          a[i] = library[i]
          return a
        }, {})
      AjaxApiFetch({
        path: "insert-library-data",
        method: "POST",
        edit: `?edit=${edit}${path ? `&path=${path}` : ""}`,
        data: { branches_keys, branches_alt_keys },
      }).then((data) => {
        this.saveFormUsers()

        this.setState({
          processing: false,
        })
        this.printMessage({
          severity: "success",
          detail: `${locale["Libray Data Inserted"]}`,
        })

        this.setState({
          redirect: isPublic,
          redirectBack: redirectPath,
        })
      })
      this.setState({
        processing: true,
      })
    } else {
      this.printMessage({
        severity: "error",
        status: `${locale["Fill all fields"]}`,
        detail: `${locale["Fill all fields"]}`,
      })
    }
  }

  // Public Form save
  savePublicForm() {
    const { branchcode_valid, fields, users } = this.state
    const validUsers = users.filter((i) => i.resp !== "").length > 0
    const branchCodeInput = document.getElementById("branchcode")

    if (branchCodeInput.value === "") branchCodeInput.focus()

    const isValid = !Object.keys(fields)
      .reduce((a, i) => {
        if (fields[i].required && fields[i].visible) a.push(!fields[i].isInvalid)
        return a
      }, [])
      .includes(false)

    if (!validUsers || !isValid || !branchcode_valid) {
      if (!isValid) {
        this.printMessage({
          severity: "error",
          detail: `${locale["Fill all fields2"]}`,
        })
        this.setState({
          popup: true,
        })
      } else if (!validUsers) {
        this.printMessage({
          severity: "error",
          detail: `${locale["Insert Users"]}`,
        })
      }
    }
    if (isValid && branchcode_valid && validUsers) {
      this.saveForm()
    }
  }

  printMessage({ severity, status, detail }) {
    this.messages &&
      this.messages.show &&
      this.messages.show({
        life: 1000,
        severity,
        summary: status,
        closable: false,
        detail,
      })
  }

  valuesForSelect(key) {
    const { software } = this.state
    switch (key) {
      case "branchcountry":
        return country
      case "branchvalid":
        return LIBRARY.YES_NO
      case "centro":
        return LIBRARY.YES_NO
      case "tipo_resp":
        return LIBRARY.LIBRARY_SELECT
      case "branch_alt_type":
        return this.state.branch_type.sort((a, b) => {
          if (a.label.split(" ")[0] > b.label.split(" ")[0]) return 1
          if (a.label.split(" ")[0] < b.label.split(" ")[0]) return -1
          return 0
        })
      case "branch_alt_loan_central":
        return LIBRARY.YES_NO
      case "branch_alt_dep":
        return LIBRARY.YES_NO
      case "branch_alt_softloan": {
        return software
      }
      default:
        return []
    }
  }

  handleUserDeletion(id) {
    const { users, form, branchcode } = this.state
    const live = form.library.branchvalid === "Y"
    const library = branchcode === "" ? form.library.branchcode : branchcode

    AjaxApiFetch({
      path: "delete-users",
      method: "GET",
      edit: `?library=${library}&live=${live}&id=${id}`,
    }).then((data) => {
      this.setState({
        users: [...users.filter((i) => i.ID !== +id)],
      })
      this.printMessage({
        severity: "success",
        status: `${locale["User Deleted"]}`,
      })
    })
  }

  saveFormUsers() {
    const { users, form, branchcode } = this.state
    const live = form.library.branchvalid === "Y"
    const library = branchcode === "" ? form.library.branchcode : branchcode

    const valid = users.filter((i) => i.resp !== "")
    if (valid.length >= 1 && library !== "") {
      this.setState({
        users_valid: true,
      })
      AjaxApiFetch({
        path: "insert-users-data",
        method: "POST",
        edit: `?library=${library}&live=${live}`,
        data: users,
      }).then((data) => {
        this.setState({
          processing: false,
          shouldSave: false,
        })
        if (data.error) {
          this.printMessage({
            severity: "error",
            status: `${locale["Fill users form"]}`,
          })
        } else {
          this.printMessage({
            severity: "success",
            status: `${locale["Users Updated / Inserted"]}`,
          })
        }
      })
    } else {
      this.printMessage({
        severity: "error",
        status: `${locale["Fill users form"]}`,
      })

      this.setState({
        processing: false,
        users_valid: false,
        shouldSave: false,
      })
    }

    this.setState({
      processing: true,
    })
  }

  addFormUsers() {
    const user = LIBRARY.USER
    const { users } = this.state
    this.setState({
      shouldSave: true,
      ...users.push(
        user.reduce((a, i) => {
          a[i.id] = ""
          return a
        }, {})
      ),
    })
  }

  validateForm() {
    const { form, fields } = this.state
    const state = form.library
    const rules = Object.values(fields)
    const validator = new FormValidator(rules)
    const validation = validator.validate(state)
    const updatedState = { ...fields }
    for (const k in validation) {
      for (const i in validation[k]) {
        updatedState[k][i] = validation[k][i]
      }
    }
    this.setState({
      fields: updatedState,
    })
  }

  validBranchCode(value) {
    const branchCodeInput = document.getElementById("branchcode")
    if (branchCodeInput) branchCodeInput.borderColor = value ? "gray" : "red"
    this.setState({
      branchcode: "",
      branchcode_valid: value,
      branchcode_message: value
        ? `${locale["Valid library code"]}`
        : `${locale["Existing library code"]}`,
    })
  }

  saveAlertContent(data) {
    const date = moment(data.branch_alt_alter_fecha).format("DD/MM/YYYY")
    const { form, branchcode } = this.state
    const live = form.library.branchvalid === "Y"
    const library = branchcode === "" ? form.library.branchcode : branchcode
    AjaxApiFetch({
      path: "insert-alert-data",
      method: "POST",
      edit: `?library=${library}&live=${live}`,
      data: { ...data, branch_alt_alter_fecha: date },
    }).then((data) => {
      this.setState({
        processing: false,
      })
      this.printMessage({
        severity: "success",
        status: `${locale["Users Updated / Inserted"]}`,
      })
    })
    this.setState({
      processing: true,
    })
  }

  showResponse() {
    this.setState({ valid: true })
    this.printMessage({
      severity: "success",
      status: `${locale["Valid library"]}`,
    })
  }

  render() {
    const {
      form,
      users,
      fields,
      active,
      redirect,
      redirectBack,
      branchcode_valid,
      shouldSave,
    } = this.state
    const { path, entity, user, button } = this.props
    const { library } = form
    const isPublicEdit = path === "public"
    const isPublicView = path === "public-viewer"

    const addLibrary = path === "add"
    const add = path === "add"
    const isAdd = path === "add"

    const isPrivateView = path === "live-library"

    const isParent =
      !isPublicEdit &&
      user &&
      user.group_children &&
      user.group_children.filter((i) => i.Libraries === form.library.branchcode)
        .length > 0

    const admin = !isPublicEdit && user && user.categorycode === GENERIC.ADMIN.TYPE
    const branchvalid = form.library && form.library.branchvalid
    const code = form.library && form.library.branchcode
    const self = !isPublicEdit && user && user.userid === form.library.branchcode
    const valid = branchvalid === "Y" || false
    const logo = form.logo && form.logo.fichero
    const isCodeValid = branchcode_valid

    return (
      <div className="">
        {this.state.loading && <ProgressSpinner />}
        {redirect && <Redirect to="/inserted" />}
        {redirectBack && <Redirect to="/home/valid" />}

        {!this.state.loading && (
          <Tabs id="library-edit" defaultActiveKey="library">
            <Tab eventKey="library" title="Biblioteca">
              <div className={`form-data-wrapper ${isPublicView ? "public" : ""}`}>
                <h2>Datos de biblioteca</h2>

                {logo && isPublicView && (
                  <img
                    style={{ padding: "2rem 0" }}
                    src={`//${server.host}:${server.port}/public/uploads/${logo}`}
                    alt={`${code} - logo`}
                  />
                )}

                {!entity && (
                  <UploadLogo
                    admin={admin}
                    self={self}
                    isParent={isParent}
                    user={user}
                    logo={logo}
                    code={code}
                    active={active}
                    upload={this.revealUploadLogo}
                    action={this.deleteLogo}
                  />
                )}

                {form && form !== undefined ? (
                  Object.keys({ ...defaultForm.library, ...form.library })
                    .filter((items) => items !== "users")
                    .map((i, k) => {
                      const value = form.library[i]

                      return (
                        <div
                          key={`${k}-${value}`}
                          className="input-data-wrapper"
                          style={{
                            display:
                              fields[i] && fields[i].visible
                                ? "inline-block"
                                : "none",
                          }}
                        >
                          <label>
                            {!isPublicView && fields[i] && fields[i].required ? (
                              <span className="required">* </span>
                            ) : (
                              ""
                            )}
                            {add && fields[i] && fields[i].alt_label
                              ? fields[i] && fields[i].alt_label
                              : fields[i] && fields[i].label}
                          </label>

                          {fields[i] && fields[i].type === "input" && (
                            <div className="input-data-wrapper-input">
                              {fields[i].field === "branchcode" && !valid && (
                                <>
                                  <div style={{ float: "left" }}>
                                    <InputText
                                      disabled={
                                        !admin && path !== "add" && path !== "public"
                                      }
                                      id={fields[i].field}
                                      onBlur={(e) =>
                                        document.getElementById("checkCode") &&
                                        document.getElementById("checkCode").click()
                                      }
                                      onChange={(e) => this.setInputValue(e)}
                                      value={form.library[i]}
                                      style={{
                                        border: "1px solid",
                                        borderColor: !entity
                                          ? this.state.user ||
                                            this.state.form.add ||
                                            this.state.form.edit
                                            ? fields[i] && !fields[i].isInvalid
                                              ? "gray"
                                              : "red"
                                            : "green"
                                          : "transparent",
                                        color:
                                          this.state.user ||
                                          this.state.form.add ||
                                          this.state.form.edit
                                            ? "#333"
                                            : "gray",
                                      }}
                                    />
                                  </div>
                                  {admin && path === "temporary-records" && (
                                    <Button
                                      label="MODIFICAR"
                                      icon="pi pi-save"
                                      disabled={isCodeValid === null || !isCodeValid}
                                      className="p-button-rounded"
                                      onClick={(e) => this.changeLibraryCode()}
                                    />
                                  )}
                                </>
                              )}

                              {fields[i].field === "branchname" && (
                                <div>
                                  <InputText
                                    id={fields[i].field}
                                    disabled={
                                      path !== "add" && path !== "public" && !admin
                                    }
                                    required={fields[i] && fields[i].required}
                                    style={{
                                      width: `${
                                        fields[i] && fields[i].size >= 0
                                          ? "100%"
                                          : "50%"
                                      }`,
                                      border: "1px solid",
                                      borderColor:
                                        (admin || !isPrivateView) && !entity
                                          ? this.state.user ||
                                            this.state.form.add ||
                                            this.state.form.edit
                                            ? fields[i] && !fields[i].isInvalid
                                              ? "gray"
                                              : "red"
                                            : "green"
                                          : "transparent",
                                      color:
                                        (!isPrivateView && this.state.user) ||
                                        this.state.form.add ||
                                        this.state.form.edit
                                          ? "#333"
                                          : "gray",
                                    }}
                                    value={form.library[i]}
                                    onBlur={(e) =>
                                      document.getElementById("checkCode") &&
                                      document.getElementById("checkCode").click()
                                    }
                                    onChange={(e) => this.setInputValue(e)}
                                  />
                                </div>
                              )}

                              {fields[i].field === "branchcode" && valid && (
                                <div>
                                  <InputText
                                    id={fields[i].field}
                                    disabled={!admin}
                                    required={fields[i] && fields[i].required}
                                    style={{
                                      width: `${
                                        fields[i] && fields[i].size >= 0
                                          ? "100%"
                                          : "50%"
                                      }`,
                                      border: "0px",
                                      borderColor: !entity
                                        ? this.state.user ||
                                          this.state.form.add ||
                                          this.state.form.edit
                                          ? fields[i] && !fields[i].isInvalid
                                            ? "gray"
                                            : "red"
                                          : "green"
                                        : "transparent",
                                      color:
                                        this.state.user ||
                                        this.state.form.add ||
                                        this.state.form.edit
                                          ? "#333"
                                          : "gray",
                                    }}
                                    value={form.library[i]}
                                    onBlur={(e) =>
                                      document.getElementById("checkCode") &&
                                      document.getElementById("checkCode").click()
                                    }
                                    onChange={(e) => this.setInputValue(e)}
                                  />
                                </div>
                              )}

                              {(admin || !isPrivateView) &&
                              !entity &&
                              this.state.user &&
                              fields[i].field !== "branchcode" &&
                              fields[i].field !== "branchname" ? (
                                <>
                                  <InputText
                                    id={i}
                                    required={fields[i] && fields[i].required}
                                    style={{
                                      width: `${
                                        fields[i] && fields[i].size >= 0
                                          ? "100%"
                                          : "50%"
                                      }`,
                                      border: "1px solid",
                                      borderColor:
                                        (admin || !isPrivateView) && !entity
                                          ? this.state.user ||
                                            this.state.form.add ||
                                            this.state.form.edit
                                            ? form.library[i] !== "" ||
                                              (fields[i] && !fields[i].isInvalid)
                                              ? "gray"
                                              : "red"
                                            : "green"
                                          : "transparent",
                                      color:
                                        (!isPrivateView && this.state.user) ||
                                        this.state.form.add ||
                                        this.state.form.edit
                                          ? "#333"
                                          : "gray",
                                    }}
                                    value={form.library[i]}
                                    onChange={(e) => {
                                      e.preventDefault()
                                      this.setInputValue(e)
                                    }}
                                  />
                                </>
                              ) : (
                                fields[i].field !== "branchcode" &&
                                fields[i].field !== "branchname" &&
                                form.library[i]
                              )}

                              {!valid && (
                                <CheckLibraryCode
                                  library={library}
                                  path={path}
                                  validate={this.validBranchCode}
                                  valid={this.state.branchcode_valid}
                                  message={this.state.branchcode_message}
                                  show={
                                    !entity &&
                                    fields[i] &&
                                    fields[i].valid === "branchcode" &&
                                    path !== "library"
                                  }
                                />
                              )}
                            </div>
                          )}

                          {fields[i] && fields[i].type === "select" && (
                            <div className="input-data-wrapper-input">
                              {(admin || !isPrivateView) && !isPublicView ? (
                                <Dropdown
                                  id={i}
                                  disabled={!!entity}
                                  value={!isNaN(value) ? +value : value}
                                  style={{
                                    border: "1px solid",
                                    borderColor:
                                      (admin || !isPrivateView) && !entity
                                        ? this.state.user ||
                                          this.state.form.add ||
                                          this.state.form.edit
                                          ? fields[i] && !fields[i].isInvalid
                                            ? "gray"
                                            : "red"
                                          : "green"
                                        : "transparent",
                                    color:
                                      (!isPrivateView && this.state.user) ||
                                      this.state.form.add ||
                                      this.state.form.edit
                                        ? "#333"
                                        : "gray",
                                  }}
                                  options={this.valuesForSelect(i)}
                                  onChange={(e) => this.setInputValue(e)}
                                  placeholder="Elegir una opcion"
                                />
                              ) : (
                                <span
                                  style={{
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {!isNaN(value)
                                    ? i !== "branch_alt_softloan"
                                      ? +value
                                      : this.state.software.find((item) => {
                                          return item.value === +value
                                        }) &&
                                        this.state.software.find((item) => {
                                          return item.value === +value
                                        }).label
                                    : i === "branchcountry"
                                    ? country.filter((i) => {
                                        return i.value === value
                                      })[0].label
                                    : value}
                                </span>
                              )}
                              <p style={{ fontSize: 12 }}>
                                {i === "branch_alt_softloan"
                                  ? "NOTA: Si dispone de un sistema de gestión de préstamo interbibliotecario (GTBIB, ILL) debe introducir la siguiente información para poder realizar la petición de documentos"
                                  : ""}
                              </p>
                            </div>
                          )}
                          {fields[i] &&
                            fields[i].type === "switch" &&
                            !isPublicView && (
                              <InputSwitch
                                disabled={!admin}
                                value={value || "N"}
                                checked={value === "Y"}
                              />
                            )}
                          <span className="error-label">
                            {fields[i] && fields[i].isInvalid && fields[i].label}
                          </span>
                          <small>
                            {!isPublicView && fields[i] && fields[i].description}
                          </small>
                        </div>
                      )
                    })
                ) : (
                  <></>
                )}
                <hr />
              </div>
              <div className="form-data-wrapper">
                {addLibrary || admin || self || isParent ? (
                  <Row>
                    <Col>
                      <Button
                        icon="pi pi-save"
                        label="Guardar"
                        className="p-button-rounded p-button-success"
                        style={{ "margin-bottom": "20px" }}
                        onClick={(e) => this.saveForm(e)}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <span> </span>
                {admin && path === "temporary-records" && !valid ? (
                  <Button
                    icon="pi pi-check"
                    label="Aprobar"
                    className="p-button-rounded p-button-success"
                    onClick={(e) => this.approveLibrary(e)}
                  />
                ) : (
                  ""
                )}
                <span> </span>
                {admin && valid ? (
                  <Button
                    icon="pi pi-times"
                    label="Eliminar"
                    className="p-button-rounded remove"
                    onClick={(e) => this.deleteLibrary(e)}
                  />
                ) : (
                  ""
                )}
                {admin && path === "temporary-records" && !valid ? (
                  <Button
                    icon="pi pi-times"
                    label="Rechazar"
                    className="p-button-rounded remove"
                    onClick={(e) => this.rejectLibrary(e)}
                  />
                ) : (
                  ""
                )}
                {button && (
                  <Button
                    icon="pi pi-arrow-left"
                    label="Volver"
                    className="right-float p-button-rounded"
                    onClick={button}
                  />
                )}
                {this.state.processing && <ProgressSpinner />}
              </div>
            </Tab>

            <Tab eventKey="users" title="Responsables">
              <div className="form-data-wrapper">
                <h2>Responsables</h2>
                {form && form !== undefined ? (
                  users.map((row, key) => {
                    const userId = row.ID
                    return Object.keys(row)
                      .filter((i) => i !== "ID" && i !== "branch_alt_code")
                      .map((i, k) => {
                        let label = ""
                        let expression = ""

                        switch (i) {
                          case "tipo_resp":
                            label = "Área"
                            break
                          case "resp":
                            label = "Responsable"
                            break
                          case "resp_tel":
                            label = "Teléfono"
                            break
                          case "resp_mail":
                            label = "Correo-e"
                            expression =
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            break
                          default:
                            label = ""
                            break
                        }
                        return (
                          <div
                            key={`${k}-${i}`}
                            className={
                              k === 1
                                ? "input-data-wrapper-users wide"
                                : "input-data-wrapper-users"
                            }
                            id={i}
                            data-row={key}
                          >
                            <label style={{ fontWeight: "800" }}>{label}</label>
                            {i !== "tipo_resp" ? (
                              <InputText
                                pattern={expression || ""}
                                id={i}
                                value={row[i]}
                                required={true}
                                disabled={!admin && isPublicView && isPrivateView}
                                style={{
                                  borderColor:
                                    (admin || !isPrivateView) && !isPublicView
                                      ? "inherit"
                                      : "transparent",
                                  color:
                                    isPrivateView && isPublicView ? "#333" : "gray",
                                }}
                                onChange={(e) => this.setUserInputValue(e)}
                              />
                            ) : (
                              <div data-row={key}>
                                <Octicon name="person" />

                                {(admin || !isPrivateView) && !isPublicView ? (
                                  <Dropdown
                                    id={`${i}-${key}`}
                                    onChange={(e) => this.setUserInputValue(e)}
                                    value={row[i].toLowerCase()}
                                    options={this.valuesForSelect(i)}
                                    required={true}
                                    disabled={
                                      !admin && isPublicView && isPrivateView
                                    }
                                    style={{
                                      borderColor:
                                        (admin || !isPrivateView) && !isPublicView
                                          ? "inherit"
                                          : "transparent",
                                      color:
                                        isPrivateView && isPublicView
                                          ? "#333"
                                          : "gray",
                                    }}
                                    placeholder="Elegir una opcion"
                                  />
                                ) : (
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {row[i]}
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        )
                      })
                      .concat([
                        (admin || self || isParent) && (
                          <div className="input-data-wrapper-remove-users">
                            <Button
                              id={userId}
                              label="Eliminar"
                              className="p-button-rounded remove"
                              onClick={(e) => {
                                this.handleUserDeletion(
                                  e.target.id !== ""
                                    ? e.target.id
                                    : e.target.parentElement.id
                                )
                              }}
                              icon="pi pi-times"
                            />
                          </div>
                        ),
                      ])
                      .concat([<hr />])
                  })
                ) : (
                  <></>
                )}
                {admin || self || isParent || path === "public" || addLibrary ? (
                  <>
                    {path !== "public" && !isAdd && (
                      <Button
                        disabled={!shouldSave}
                        icon="pi pi-users"
                        label="Guardar Usuarios"
                        className="p-button-rounded p-button-success"
                        onClick={(e) => this.saveFormUsers(e)}
                      />
                    )}
                    <span> </span>
                    <Button
                      icon="pi pi-plus"
                      label="Adicionar"
                      className="p-button-rounded p-button-success"
                      onClick={(e) => this.addFormUsers(e)}
                    />
                    {this.state.processing && <ProgressSpinner />}
                  </>
                ) : (
                  <></>
                )}
                <hr />
              </div>
            </Tab>

            {path !== "public" && (
              <Tab eventKey="alert" title="Alertas">
                <div className="form-data-wrapper">
                  <h2>Alertas</h2>
                  <AlertForm
                    isAdd={isAdd}
                    isSelf={self}
                    isPublic={isPublicView}
                    isParent={isParent}
                    isAdmin={admin}
                    library={form.library}
                    saveAlertContent={this.saveAlertContent}
                  />
                </div>
              </Tab>
            )}

            {(admin || add || self || isParent || path === "public") && !logo && (
              <Tab eventKey="logo" title="Subir logo">
                <div className="form-data-wrapper">
                  <h2>Logo</h2>
                  <Upload code={code} type="image" />
                </div>
              </Tab>
            )}
            <hr />
          </Tabs>
        )}
        <div
          className="tab-content"
          style={{
            backgroundColor: "#fff",
            overflow: "hidden",
            padding: "0 35px 35px",
          }}
        >
          {/* Public Form Edit */}
          {isPublicEdit && (
            <div className="form-data-wrapper">
              <small>
                {!this.state.valid && !user
                  ? "Resuelve captcha antes de guardar"
                  : ""}
              </small>
              {window.grecaptcha &&
              window.grecaptcha.render &&
              !this.state.valid &&
              isPublicEdit ? (
                <Captcha
                  language="es"
                  siteKey={process.env.REACT_APP_CAPTCHAKEY}
                  onResponse={this.showResponse}
                />
              ) : (
                ""
              )}
              <br />
              <Button
                disabled={!this.state.valid && isPublicEdit}
                icon="pi pi-save"
                label="Enviar Solicitud"
                className="p-button-rounded p-button-success"
                onClick={this.savePublicForm}
              />
            </div>
          )}
        </div>

        <Dialog
          header="Atención!"
          visible={this.state.popup}
          style={{ width: "50vw" }}
          modal={true}
          onHide={() => this.setState({ popup: false })}
        >
          <h5>{locale["library Save Error"]}</h5>
        </Dialog>

        <div style={{ zIndex: 10000 }}>
          <Messages ref={(el) => (this.messages = el)} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.login.user || JSON.parse(localStorage.getItem("user")),
})

export default connect(mapStateToProps, { getLibrary, deleteLibrary })(Library)
