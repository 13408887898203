import React, { Component } from "react"
import { Button } from "primereact/button"
import { Row, Col } from "react-bootstrap"
import { Messages } from "primereact/messages"
import { Dialog } from "primereact/dialog"

import { ProgressSpinner } from "primereact/progressspinner"
import { AjaxApiFetch } from "../../Utils/ajaxApiFetch"

export class HoldingsButtons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.deleteHoldings = this.deleteHoldings.bind(this)
  }

  deleteHoldings(type, label) {
    const { live, group, handleUpdate } = this.props

    this.setState({
      visible: true,
      message: {
        header: `${label}`,
        message: `${label} - ${type} de ${group}`,
        onclick: () => {
          this.setState({
            loading: true,
            message: null,
          })

          AjaxApiFetch({
            path: "delete-holdings-batch",
            edit: `?group=${group}&live=${live}&type=${type}`,
          }).then((data) => {
            if (data.error) {
              this.messages.show({
                severity: "error",
                summary: "Fondos",
                closable: false,
                detail: data.error,
              })
            } else {
              handleUpdate()
              this.messages.show({
                severity: "success",
                summary: "Fondos",
                closable: false,
                detail: data.success,
              })
            }
            this.setState({
              loading: false,
            })
          })
        },
      },
    })
  }

  render() {
    const { message } = this.state
    return (
      <div>
        {this.state.loading && (
          <>
            <ProgressSpinner />
            <br />
            <br />
          </>
        )}
        <Row>
          <Col xs={4}>
            <Button
              icon="pi pi-save"
              onClick={(e) =>
                this.deleteHoldings("EJNL-", "Borrar fondos consorciados")
              }
              label="Borrar fondos consorciados"
              className="p-button-rounded remove"
            />
          </Col>
          <Col xs={4}>
            <Button
              icon="pi pi-save"
              onClick={(e) => this.deleteHoldings("JNL", "Borrar fondos impresos")}
              label="Borrar fondos impresos"
              className="p-button-rounded remove"
            />
          </Col>
          <Col xs={4}>
            <Button
              icon="pi pi-save"
              onClick={(e) =>
                this.deleteHoldings("EJNL", "Borrar fondos electrónicos")
              }
              label="Borrar fondos electrónicos"
              className="p-button-rounded remove"
            />
          </Col>
        </Row>

        {message && (
          <Dialog
            header={message.header}
            visible={this.state.visible}
            style={{ width: "50vw" }}
            modal
            onHide={() => this.setState({ visible: false })}
          >
            <p>{message.message}</p>

            <Button
              icon="pi pi-trash"
              label="Cancelar"
              className="p-button-rounded cancel"
              onClick={() => this.setState({ visible: false })}
            />

            <Button
              icon="pi pi-trash"
              label="Confirmar"
              className="p-button-rounded remove"
              onClick={message.onclick}
            />
          </Dialog>
        )}

        <Messages ref={(el) => (this.messages = el)} />
      </div>
    )
  }
}
