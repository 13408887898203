import React, { Component } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Messages } from "primereact/messages"
import { ProgressSpinner } from "primereact/progressspinner"
import { AjaxApiFetch } from "../Utils/ajaxApiFetch"
import { locale } from "../constants/language.config"

export class Logs extends Component {
  constructor(props) {
    super(props)
    this.buildTable = this.buildTable.bind(this)
    this.loadFileTemplate = this.loadFileTemplate.bind(this)
    this.loadFileTemplateAction = this.loadFileTemplateAction.bind(this)
    this.state = {
      library: this.props.group,
      rows: [],
      columns: [],
      message: { header: "", message: "" },
      loading: false,
    }
  }

  buildTable(data) {
    const columns =
      data.length > 0
        ? Object.keys(data[0]).map((i) => ({ field: i, header: i }))
        : []
    this.setState({
      rows: data,
      columns,
    })
  }

  componentDidMount() {
    const { library } = this.state
    const config = {
      path: "uploads-log",
      group: `?library=${library}`,
    }
    AjaxApiFetch(config).then((data) => {
      if (data.error) return
      this.buildTable(data)
    })
  }

  loadFileTemplateAction(e) {
    const id = e
    const { library } = this.state
    const { consorcio } = this.props

    const config = {
      path: "upload-file",
      group: `?library=${library}&consorcio=${consorcio}&id=${id}`,
    }
    this.setState({
      loading: true,
    })

    AjaxApiFetch(config).then((data) => {
      if (data.error) {
        this.messages.show({
          severity: "error",
          summary: "Fondos",
          closable: false,
          detail: `${data.error}`,
        })
      } else {
        this.messages.show({
          severity: "success",
          summary: "Fondos",
          closable: false,
          detail: "Petición actualizada",
        })
      }
      this.setState({
        loading: false,
      })
    })
  }

  loadFileTemplate(rowData, column) {
    if (column.field === "upload") {
      return (
        <Button
          className="p-button-rounded"
          onClick={(e) => this.loadFileTemplateAction(rowData.path)}
          id={rowData.path}
          icon="pi pi-upload"
          label="Importar"
        >
          {rowData["Tipo de Fondo"]}
        </Button>
      )
    }
    return rowData[column.field]
  }

  render() {
    const { rows, columns } = this.state
    const dynamicColumns = columns
      .filter(
        (col) =>
          col.field !== "branchname" && col.field !== "id" && col.field !== "path"
      )
      .concat([{ field: "upload", header: "upload" }])
      .map((col, i) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          filter={false}
          sortable
          body={this.loadFileTemplate}
        />
      ))
    const header = (
      <div style={{ overflow: "hidden" }}>
        <div style={{ textAlign: "left", float: "left" }}>
          <i
            className="pi pi-search"
            style={{ margin: "4px 4px 0 0", display: "inline" }}
          />
          <InputText
            type="search"
            placeholder="Búsqueda"
            style={{ display: "inline", width: "90%" }}
            onInput={(e) => this.setState({})}
            size="50"
          />
        </div>
        <div style={{ textAlign: "right", float: "right" }}>
          <Button
            type="button"
            icon="pi pi-external-link"
            iconPos="left"
            className="p-button-rounded"
            label="Exportar"
            onClick={this.export}
          />
        </div>
      </div>
    )

    return (
      <>
        {this.state.loading && (
          <>
            <ProgressSpinner />
            <br />
            <br />
          </>
        )}
        <Messages ref={(el) => (this.messages = el)} />
        <DataTable
          responsive
          ref={(el) => (this.dt = el)}
          value={rows}
          paginator
          header={header}
          globalFilter={this.state.globalFilter}
          emptyMessage={`${locale["No records found"]}`}
          rows={50}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          resizableColumns
          exportFilename
        >
          {dynamicColumns}
        </DataTable>
      </>
    )
  }
}
//
