export const labels = {
  library: {
    branchcode: "Código de la biblioteca",
    branchname: "Nombre de la biblioteca",
    branchaddress1: "",
    branchaddress2: "",
    branchaddress3: "",
    branchzip: "Código postal",
    branchcity: "Ciudad",
    branchstate: "",
    branchcountry: "",
    branchphone: "Teléfono",
    branchfax: "",
    branchemail: "",
    branchreplyto: "",
    branchreturnpath: "",
    branchurl: "",
    issuing: "",
    branchip: "",
    branchnotes: "",
    branchvalid: "",
    opac_info: "",
    branchobs: "",
  },
  login: {
    username: "Usuario",
    password: "Contraseña",
    headline: "Acceso restringido a los centros participantes del CNCS. ",
    message: "Módulo de Administración y solicitud de documentos.",
  },
  user_management: {
    Group: "Código centro",
    "Nombre del centro de compra": "Miembro de la red",
    "Fondo Consorciado": "Código fondo red",
    "Centro consorciado": "Miembro de red",
    "Centro de compra": "Responsable de red",
  },
}

export default labels
