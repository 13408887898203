import React, { Component } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Library from "../components/Library"
import { AjaxApiFetch } from "../Utils/ajaxApiFetch"
import { Header } from "./shared/Header"

export class PublicViewLibrary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      library: this.props.match.params.library,
    }
    this.buildLibrary = this.buildLibrary.bind(this)
  }

  buildLibrary(data) {
    this.setState({
      data,
    })
  }

  shouldComponentUpdate(prevProps, newProps) {
    return true
  }

  componentDidMount() {
    const { library } = this.state
    AjaxApiFetch({
      path: "library-data",
      group: `/?library=${library}`,
    }).then((data) => {
      if (data.error) return
      this.buildLibrary(data.pop())
    })
  }

  render() {
    const { library, data } = this.state

    const { history } = this.props

    return (
      <div className="public">
        {data && <Header {...data.library} />}

        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12} md={10}>
              <Library history={history} entity={library} path="public-viewer" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={10}>
              <footer className="footer">
                Instituto de Salud Carlos III. Biblioteca Nacional de Ciencias de la
                Salud - Avda. Monforte de Lemos 5. 28029 Madrid.
                <a href="mailto:cncs@isciii.es">Contacto: cncs@isciii.es</a>
              </footer>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
