import React, { Component } from "react"
import { withRouter, Redirect, Route } from "react-router-dom"
import { connect } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"

import Header from "../components/shared/Header"

import { AjaxApiFetch } from "../Utils/ajaxApiFetch.js"

import "bootstrap/dist/css/bootstrap.css"
import "primereact/resources/themes/nova-light/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

import { Footer } from "../components/shared/Footer"
import { Ping } from "../components/CheckStatus/Ping"

import ControlledTabs from "../components/Navigation/ControlledTabs"

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      library: {},
      country: [],
      group: null,
      redirect: false,
    }
  }

  componentDidMount() {
    let user = this.props.location.state

    if (!user) {
      try {
        user = JSON.parse(localStorage.getItem("user"))
      } catch (error) {
        this.setState({ redirect: true })
      }
    }
    this.fetchData(user)
  }

  componentDidUpdate(prev, props) {
    const { library } = this.state
    if (!library.branchcode) {
      let parsedUser
      try {
        parsedUser = JSON.parse(localStorage.getItem("user"))
        this.fetchData(parsedUser)
      } catch (error) {
        this.setState({ redirect: true })
      }
    }
  }

  buildSelect(data) {
    this.setState({ country: data })
  }

  fetchData(user) {
    if (!user || !(user.userid && user.branchcode)) {
      this.setState({ redirect: true })
    } else {
      const group = user && user.group_id && user.group_id.shift()
      const parent = group && group.nombre_padre === user.othernames

      AjaxApiFetch({
        path: "library-data",
        group: `/?library=${
          user.categorycode !== "S" ? user.userid : user.branchcode
        }`,
      }).then((data) => {
        if (data.error) return
        this.setState({ user })

        const table = { ...data[0], parent }
        this.buildLibrary(table)
      })
      AjaxApiFetch({ path: "country-select-data" }).then((data) => {
        this.buildSelect(data)
      })
    }
  }

  buildLibrary(data) {
    if (!data.library && data.error) return
    let { user } = this.state
    if (!user) user = JSON.parse(localStorage.getItem("user"))

    const { library, users, branch_type, parent, logo } = data
    this.setState({
      library,
      users,
      branch_type,
      group: user.userid,
      parent,
      logo,
      user,
    })
  }

  render() {
    const { group, redirect } = this.state
    return (
      <div className="root">
        {group ? (
          <div className="root-wrap">
            <Container>
              <Row>
                <Col xs={12}>
                  <div className="header-wrap">
                    <Header data={this.props.location.state} />
                  </div>
                </Col>
              </Row>
              <Route
                path="/home"
                render={(props) => <ControlledTabs {...props} data={this.state} />}
              />
              <div style={{ marginTop: "100px" }}>
                <Row>
                  <Footer />
                </Row>
              </div>
            </Container>
          </div>
        ) : (
          <div className="intro-logo-wrapper">
            <Ping />
            <img className="intro-logo" src="/logo.svg" alt="logo" />
            <i className="pi pi-spin pi-spinner" style={{ fontSize: "3em" }} />
            <span>Cargar Aplicacion</span>
          </div>
        )}
        {redirect && (
          <Redirect
            to={{
              pathname: "/login",
              state: { prevlocation: this.props.location },
            }}
          />
        )}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  login: state.login,
})
export default connect(mapStateToProps)(withRouter(Home))
