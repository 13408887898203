import * as types from "../actions"

export default (state = [], action) => {
  const { user } = action
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      return { ...state, user }
    case types.LOGIN_USER_ERROR:
      return { ...state, user }
    default:
      return state
  }
}
