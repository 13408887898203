import React from "react"
import { Col } from "react-bootstrap"
import ReactTooltip from "react-tooltip"
import { Link } from "react-router-dom"
import { MenuLink } from "./MenuLink"

const Menu = (combined) => {
  const { user, parent } = combined
  const isAdmin = user.categorycode === "S"
  const isParent = parent

  return (
    <Col xs={1}>
      <ReactTooltip backgroundColor="#337991" />
      <div className="menu-fixed">
        <h1>
          <Link to="/home" data-tip="Enter your username">
            <img className="left-intro-logo" src="/logo.svg" alt="Logo" />
          </Link>
        </h1>
        <ul>
          <MenuLink
            activeOnlyWhenExact
            to="/home"
            label="Inicio"
            icon="home"
            tooltip="Página de Inicio"
          />
          <MenuLink
            to={`https://${process.env.REACT_APP_VUFIND_URL}`}
            label="Catálogo Online"
            icon="book"
            tooltip="Catálogo Online"
            external
          />
          <MenuLink
            activeOnlyWhenExact
            to="/home/request-holdings"
            label="Solicitar Artículo"
            icon="file"
            tooltip="Solicitar Artículo"
          />
          {isAdmin && (
            <MenuLink
              activeOnlyWhenExact
              to="/home/manage-issn"
              label="Gestión Issn"
              icon="home"
              tooltip="Gestión de issn Sin Correspondencia"
            />
          )}
          {!isAdmin && (
            <MenuLink
              activeOnlyWhenExact
              to="/home/edit-self"
              label="Editar Biblioteca"
              icon="pencil"
              tooltip="Editar Biblioteca"
            />
          )}
          {!isAdmin && (
            <MenuLink
              to="/home/holdings/self/:tab"
              label="Gestión Fondos"
              icon="package"
              tooltip="Gestión de Fondos"
            />
          )}
          <MenuLink
            to="/home/add"
            label="Alta biblioteca"
            icon="check"
            tooltip="Solicitar Alta de biblioteca"
          />
          {isParent ? (
            <MenuLink
              to="/home/groups"
              label="Gestión Red"
              icon="flame"
              tooltip="Gestión de la Red"
            />
          ) : (
            ""
          )}
          {isAdmin ? (
            <MenuLink
              to="/home/valid"
              label="Alta Bibliotecas"
              icon="watch"
              tooltip="Alta bibliotecas"
            />
          ) : (
            ""
          )}
          {isAdmin ? (
            <MenuLink
              activeOnlyWhenExact
              to="/home/perms"
              label="Gestión Grupos"
              icon="organization"
              tooltip="Gestión de los Grupos y Permisos"
            />
          ) : (
            ""
          )}

          <MenuLink
            activeOnlyWhenExact={false}
            to="/home/libraries"
            label="Lista Bibliotecas"
            icon="list-unordered"
            tooltip="Lista de Bibliotecas de la Red"
          />

          {isAdmin ? (
            <MenuLink
              activeOnlyWhenExact
              to="/home/blacklist"
              label="Blacklist"
              icon="x"
              tooltip="Lista de Fondos en Blacklist"
            />
          ) : (
            ""
          )}
          {!isAdmin && (
            <MenuLink
              activeOnlyWhenExact
              to="/home/requests"
              label="Gestión Solicitudes"
              icon="mail-read"
              tooltip="Gestión de Solicitudes"
            />
          )}
        </ul>
      </div>
    </Col>
  )
}
export default Menu
